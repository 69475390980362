import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Checkbox, InputAdornment, Stack, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { useSession } from '~/utils/contexts';
import { MyCheckbox, MyTextInput } from '~/components/CustomInputElements';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import { addHours, differenceInYears, isValid, parse } from 'date-fns';

export const DetailsForm = () => {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const { currentFormRef, updateSession, setAllowProgress, handleSubmitRef, session } =
		useSession();

	const handleSubmit = async (values) => {
		await updateSession({
			details: {
				firstName: values.firstName,
				lastName: values.lastName,
				email: values.email,
				phoneNumber: { countryCode: '44', phone: values.phone },
				agreedToC: values.acceptedTerms,
				agreedToOffers: values.acceptedOffers,
				dob:
					new Date(
						new Date(values.dob).getTime() -
							new Date(values.dob).getTimezoneOffset() * 60000
					).toString() ?? new Date(Date.now()),
			},
		});
	};

	return (
		<Styles>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Formik
					enableReinitialize={true}
					innerRef={currentFormRef}
					initialValues={{
						firstName: session?.details?.firstName,
						lastName: session?.details?.lastName,
						email: session?.details?.email,
						acceptedTerms: false, // added for our checkbox
						acceptedOffers: false,
						phone: session?.details?.phoneNumber.phone,
						dob: session?.details?.dob
							? moment.utc(session?.details?.dob)
							: moment.utc('01/01/2000'),
					}}
					validationSchema={Yup.object({
						firstName: Yup.string()
							.max(15, 'Must be 15 characters or less')
							.required('Required'),
						lastName: Yup.string()
							.max(20, 'Must be 20 characters or less')
							.required('Required'),
						email: Yup.string().email('Invalid email address').required('Required'),
						phone: Yup.string()
							.matches(phoneRegExp, 'Invalid phone number')
							.required('Required')
							.max(11, 'That phone number is too long.')
							.min(8, 'That phone number is too short'),
						acceptedTerms: Yup.boolean()
							.required('Required')
							.oneOf([true], 'Please accept the terms before continuing'),
						acceptedOffers: Yup.boolean(),
						dob: Yup.date()
							.nullable()
							.typeError('The date format is wrong please use DD/MM/YYYY')
							.required('Required')
							.test('dob', 'You must be older than 18', function (value, ctx) {
								if (!value) return ctx.createError();
								try {
									const validDate = new Date();
									const valid = differenceInYears(validDate, value) >= 18;
									return !valid ? ctx.createError() : valid;
								} catch (e) {
									console.error(e);
									return ctx.createError();
								}
							}),
					})}
					onSubmit={handleSubmit}
				>
					{(formik) => {
						const {
							errors,
							touched,
							isValid: reportedErrors,
							dirty,
							values,
							setFieldValue,
						} = formik;

						useEffect(() => {
							setAllowProgress(dirty && reportedErrors);
						}, [reportedErrors, dirty]);

						// Control the next button internally
						handleSubmitRef!.current = async () => {
							if (!reportedErrors) return false;

							try {
								await handleSubmit(values);
							} catch (e) {
								return false;
							}
							return true;
						};

						return (
							<Form className="form">
								<MyTextInput
									label="First Name"
									name="firstName"
									type="text"
									placeholder=""
								/>

								<MyTextInput
									label="Last Name"
									name="lastName"
									type="text"
									placeholder=""
								/>

								<MyTextInput
									label="Email Address"
									name="email"
									type="email"
									placeholder=""
								/>
								<MyTextInput
									label="Phone number"
									name="phone"
									type="tel"
									placeholder=""
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">+44</InputAdornment>
										),
									}}
								/>

								<DatePicker
									openTo="year"
									className="input-box"
									label="Date of Birth"
									inputFormat="dd/MM/yyyy"
									value={values.dob}
									onChange={(value, t) => {
										if (value) {
											if (
												value.getFullYear() >= 50 &&
												value.getFullYear() <= 99
											) {
												value.setFullYear(value.getFullYear() + 1900);
											} else if (value.getFullYear() < 9) {
												value.setFullYear(value.getFullYear() + 2000);
											}
										}
										setFieldValue('dob', value);
									}}
									desktopModeMediaQuery="@media (min-width: 0px)"
									renderInput={(params) => (
										<TextField
											fullWidth
											{...params}
											error={!!errors['dob']}
											helperText={
												errors['dob']
													? // @ts-ignore
													  errors['dob']
													: params?.helperText
											}
										/>
									)}
									maxDate={moment().subtract(18, 'years')}
									minDate={moment('01/01/1920')}
								/>

								<MyCheckbox name="acceptedTerms">
									I accept the{' '}
									<a
										className="terms-link"
										href="https://armakarma.insure/terms-conditions"
										target="_blank"
										style={{ whiteSpace: 'nowrap' }}
									>
										terms and conditions
									</a>
									&nbsp;and&nbsp;
									<a
										className="terms-link"
										href="https://armakarma.insure/privacy-policy-2"
										target="_blank"
										style={{ whiteSpace: 'nowrap' }}
									>
										privacy policy
									</a>
								</MyCheckbox>
								<MyCheckbox name="acceptedOffers">
									I’m happy to receive rewards, offers and news from Arma Karma
								</MyCheckbox>
							</Form>
						);
					}}
				</Formik>
			</LocalizationProvider>
		</Styles>
	);
};

const Styles = styled.div`
	.form {
		display: flex;
		flex-direction: row;
	}

	.terms-link {
		color: ${({ theme }) => theme.palette.primary.darker};
		text-decoration: underline;
		transition: 0.2s ease;

		&:hover {
			color: ${({ theme }) => theme.palette.primary.main};
		}
	}

	.input-box {
		margin: 8px 0px;
		.MuiInputAdornment-root {
			margin-right: 0px !important;
		}
	}
`;

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import OptionGrid from '~/components/OptionGrid';
import { ICharity } from '~/models/Charity';
import { Button, Grid, Stack, Typography } from '@mui/material';
import GridItem from '~/components/GridItem';
import DialogPopup from '~/components/DialogPopup';
import { useSession } from '~/utils/contexts';
import theme from '~/components/Theme';
import api, { useGet } from '~/utils/api';

const SelectCharity: React.FC<{}> = () => {
	const { data: charitiesResult } = useGet<{ results: ICharity[]; chosenCharity?: ICharity }>(
		api.charity.charities
	);

	const charities = charitiesResult?.results;

	const [currentCharity, setCurrentCharity] = useState<ICharity | null>(null);
	const [charityQueried, setCharityQueried] = useState<ICharity | null>(null);

	const { setAllowProgress, setCharity } = useSession();

	const [apiCharityUsed, setApiCharityUsed] = useState<boolean>(false);

	useEffect(() => {
		setAllowProgress(currentCharity !== null);
	}, [currentCharity]);

	const getStatus = (item: ICharity): 'active' | 'inactive' | 'white' => {
		if (currentCharity === null) return 'white';
		if (currentCharity?.name === item?.name) return 'active';
		return 'inactive';
	};

	const handleCharitySelected = (item: ICharity) => {
		setCharityQueried(item);
	};

	const selectRandomCharity = () => {
		if (!charities?.length) return;

		if (!apiCharityUsed && charitiesResult?.chosenCharity?._id) {
			setApiCharityUsed(true);
			setCurrentCharity(charitiesResult?.chosenCharity || null);
			setCharity(charitiesResult?.chosenCharity?._id, true);
		} else {
			let randomCharity = Math.floor(Math.random() * charities.length);

			//needed to that the new random charity is not the same as the previously selected one
			if (currentCharity) {
				if (randomCharity === charities.findIndex((x) => x.name === currentCharity.name)) {
					randomCharity = (randomCharity + 1) % charities.length;
				}
			}
			setCurrentCharity(charities[randomCharity]);
			setCharity(charities[randomCharity]._id, true);
		}
	};

	return (
		<Styles>
			<DialogPopup dialogOpen={!!charityQueried} setDialogOpen={setCharityQueried}>
				<Stack className="charity-info-stack" sx={{ width: '350px', maxWidth: '100%' }}>
					<Typography variant="h3" className="dialog-title">
						{charityQueried?.name}
					</Typography>

					<Typography variant="body1" className="dialog-body" sx={{ py: 2 }}>
						{charityQueried?.description}
					</Typography>

					<Typography
						variant="h3"
						component={'a'}
						href={charityQueried?.moreInfo}
						target="_blank"
						className="dialog-link"
						sx={{ pb: 3, color: 'black' }}
					>
						More info
					</Typography>

					<Button
						className="charity-info-stack-button"
						onClick={() => {
							setCurrentCharity(charityQueried);
							setCharity(charityQueried!._id);
							setCharityQueried(null);
						}}
						variant="contained"
					>
						Select
					</Button>
				</Stack>
			</DialogPopup>

			<Stack justifyContent="center" alignItems="center" className="charity-stack">
				<Button variant="outlined" className="pick-for-me" onClick={selectRandomCharity}>
					Pick for me!
				</Button>

				<Grid container spacing={3}>
					{charities?.map((item, index) => (
						<Grid
							item
							xs={6}
							md={3}
							xl={3}
							key={index}
							onClick={() => handleCharitySelected(item)}
						>
							<GridItem
								status={getStatus(item)}
								code={''}
								name={item?.name}
								img={item?.imageUrl}
								showQuestion={true}
								_id={index.toString()}
							/>
						</Grid>
					))}
				</Grid>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	.charity-stack {
		gap: ${({ theme }) => theme.spacing(4)};
	}

	.pick-for-me {
		border-radius: 8px;
		border: 3px solid ${({ theme }) => theme.palette.primary.main};
		box-shadow: 3px 3px 0px 0px ${({ theme }) => theme.palette.primary.main};
		margin: auto;
		color: ${({ theme }) => theme.palette.primary.dark};
		background-color: white;

		&:focus,
		&:active {
			box-shadow: 3px 3px 0px 0px ${({ theme }) => theme.palette.primary.main};
			border: 3px solid ${({ theme }) => theme.palette.primary.main};
		}
	}

	.charity-info-stack {
		&-button {
			margin-top: ${({ theme }) => theme.spacing(4)};
		}
	}
`;

export default SelectCharity;

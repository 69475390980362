import React from 'react';
import styled from '@emotion/styled';
import { Container, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const HighlightedTitle: React.FC<{
	location: Location;
	title: string;
	subtitle: string;
	underlineLastWord?: boolean;
}> = ({ title, subtitle, underlineLastWord = false, location }) => {
	const titleMappings = [
		{
			step: 'item-selection',
			title: 'select your items',
			subtitle: 'Pick the items you’d like to protect',
		},
		{
			step: 'add-on',
			title: 'add ons',
			subtitle: 'something a little extra',
		},
		{
			step: 'name',
			title: "let's get you set up",
		},
		{
			step: 'address',
			title: 'your address',
		},
		{
			step: 'email-phone',
			title: 'your contact details',
			subtitle: "we'll use this to send your policy",
		},
		// {
		// 	step: 'verify-phone',
		// 	title: 'verify phone',
		// 	subtitle: 'verify your phone number',
		// 	underlineLastWord: false,
		// },

		{
			step: 'disclaimers',
			title: 'general disclaimers',
			subtitle: '',
		},
		{
			step: 'charity',
			title: 'select your chosen charity',
			subtitle: "25% of Arma Karma's commission goes to your chosen charity",
		},
		// {
		// 	step: 'start-date',
		// 	title: 'select your start date',
		// 	subtitle: '',
		// 	underlineLastWord: false,
		// },
		{
			step: 'checkout',
			title: 'Complete your subscription',
			subtitle: '',
		},
		{
			step: 'thank-you',
			title: 'thank you!',
			subtitle: '',
		},
		{
			step: 'manual-entry',
			title: 'your address',
			subtitle: 'enter your details below',
		},
	];

	const titleValues =
		titleMappings.find((x) => location.pathname.includes(x.step)) ?? titleMappings[0];

	return (
		<Styles>
			<Container className="wrapper">
				<Typography color="primary.dark" textAlign="center" variant="h1" className="">
					{titleValues.title}
				</Typography>

				<Typography mt={1} variant="subtitle1" className="subtitle">
					{titleValues.subtitle}
				</Typography>
			</Container>
		</Styles>
	);
};

const Styles = styled.div`
	.wrapper {
		padding: ${({ theme }) => theme.spacing(4, 2, 2, 2)};
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		${({ theme }) => theme.breakpoints.down('md')} {
			padding: ${({ theme }) => theme.spacing(3, 2, 2, 2)};
		}

		&-title {
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			position: relative;
			text-transform: uppercase;

			&#thank {
				background-image: url('https://arma-karma.s3.eu-west-2.amazonaws.com/assets/underline.svg');
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: bottom;
			}

			&#thank::before {
				content: '';
				position: absolute;
				left: 98%;
				top: -4px;
				width: 30px;
				height: 30px;
				transform: rotateZ(0deg);
				background-image: url('https://arma-karma.s3.eu-west-2.amazonaws.com/assets/Thankyou-edges.svg');
			}

			&#thank::after {
				content: '';
				position: absolute;
				left: -14%;
				top: 0px;
				width: 30px;
				height: 30px;
				transform: rotateZ(230deg);
				background-image: url('https://arma-karma.s3.eu-west-2.amazonaws.com/assets/Thankyou-edges.svg');
			}

			.underlined {
				background-image: url('https://arma-karma.s3.eu-west-2.amazonaws.com/assets/underline.svg');
				background-repeat: no-repeat;
				background-size: 90%;
				background-position: center 90%;
			}
		}

		.subtitle {
			text-align: center;
			padding-bottom: ${({ theme }) => theme.spacing(2)};
		}
	}
`;

export default HighlightedTitle;

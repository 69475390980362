import styled from '@emotion/styled';
import { CheckCircle } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
	Box,
	Card,
	IconButton,
	Stack,
	Switch,
	TextField,
	Typography,
	Skeleton,
	useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasketItem from '~/components/BasketItem';
import KarmadilloSpinner from '~/components/KarmadilloSpinner';
import { SnackbarContext } from '~/components/Snackbar/Snackbar';
import { allowBasketEdits } from '~/utils/common';
import { SessionContext } from '~/utils/contexts';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const DrawerContent: React.FC<{ setOpen: (open: boolean) => void }> = ({ setOpen }) => {
	// const defaultBasketItems: IBasketItem[] = [
	// 	{
	// 		name: 'Airpods Pro',
	// 		price: 4.99,
	// 		code: 'airpods-second-generation',
	// 		icon: 'headphones-simple',
	// 	},
	// {name: 'iphone 14 pro max', price: 8.99, code: 'iphone', icon: 'phone'},
	// {name: 'Playstation 5', price: 5.99, code: 'playstation', icon: 'controller'},
	// ];

	const [showDiscountBox, setShowDiscountBox] = useState<boolean>(false);

	const {
		session,
		updateSession,
		totalPrice,
		handleSubmitRef,
		currentFormRef,
		allowProgress,
		stepIndex,
		step,
		isFetchingTotalPrice,
		error,
	} = useContext(SessionContext);
	const [waiting, setWaiting] = useState(false);
	const [removingDiscount, setRemovingDiscount] = useState(false);
	const [discountText, setDiscountText] = useState<string | null>(null);
	const { openSnackbar } = useContext(SnackbarContext);

	const navigate = useNavigate();

	const theme = useTheme();

	const $trustpilot = useRef<HTMLDivElement>(null);
	useEffect(() => {
		// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
		// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
		// When it is, it will automatically load the TrustBox.
		if ((window as any).Trustpilot) {
			(window as any).Trustpilot.loadFromElement($trustpilot.current, true);
		}
	}, []);

	useEffect(() => {
		setDiscountText(session?.discountCode);
		setShowDiscountBox(!!session?.discountCode);
	}, [session?.discountCode]);

	const handleDiscountAttempt = async () => {
		setWaiting(true);
		try {
			const session = await updateSession({
				discountCode: discountText?.toUpperCase() ?? '',
			});
		} catch (e) {
			openSnackbar(
				(e as any)?.response?.data?.errorMessage ?? (e as any)?.response?.data?.errorName,
				'error'
			);
		}
		setWaiting(false);
	};

	const handleRemoveDiscount = async () => {
		setRemovingDiscount(true);
		setWaiting(true);
		try {
			const session = await updateSession({
				discountCode: null,
			});
		} catch (e) {
			openSnackbar(
				(e as any)?.response?.data?.errorMessage ?? (e as any)?.response?.data?.errorName,
				'error'
			);
		}
		setRemovingDiscount(false);
		setDiscountText('');
		setWaiting(false);
	};

	const deleteAddon = (addOnToDelete: string) => {
		const newAddons = session?.addOns.filter((addOn) => addOn !== addOnToDelete);
		void updateSession({ addOns: newAddons });
	};

	return (
		<Styles>
			<Box className="content">
				<Box mt={4} mb={4} className="products-container">
					{session?.products?.map((item, index) => (
						<BasketItem
							StackProps={{ sx: { justifyContent: 'center !important' } }}
							BasketItemIconProps={{ sx: { ml: '0px !important' } }}
							key={index}
							item={item}
							category=""
							edit
						/>
					))}
				</Box>
				<Stack spacing={6}>
					<Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
						<Typography variant="subtitle1" className="add-more-text">
							Add more
						</Typography>
						<IconButton
							className="icon-wrapper add-more-icon"
							onClick={(e) => {
								e.preventDefault();
								setOpen(false);
								navigate('/');
							}}
						>
							<AddIcon className="icon-wrapper-icon" />
						</IconButton>
					</Stack>
					<Stack justifyContent="center" direction="row" alignItems="center">
						<Typography variant="h6" className="">
							Got a discount code?
						</Typography>

						<Switch
							value={showDiscountBox}
							checked={showDiscountBox}
							disabled={!!session?.discountCode || !allowBasketEdits()}
							onChange={(event, checked) => setShowDiscountBox(checked)}
						/>
					</Stack>
					<Typography
						className="what-covered-link"
						component="a"
						href="https://armakarma.insure/documents"
						target={'_blank'}
						referrerPolicy={'no-referrer'}
					>
						Cover Summary
					</Typography>

					{/*TrustBox widget - Micro Star*/}
					<div
						className="trustpilot-widget"
						ref={$trustpilot}
						data-locale="en-GB"
						data-template-id="5419b732fbfb950b10de65e5"
						data-businessunit-id="5e7b7d7e193e49000122ab0e"
						data-style-height="24px"
						data-style-width="100%"
						data-theme="light"
					>
						<a
							href="https://uk.trustpilot.com/review/armakarma.insure"
							target="_blank"
							rel="noopener"
						>
							Trustpilot
						</a>
					</div>
					{/*End TrustBox widget*/}
				</Stack>

				{(session?.addOns?.length ?? 0) > 0 && (
					<Typography variant="h3" className="addon-title">
						Add-ons:{' '}
					</Typography>
				)}
				{session?.addOns?.map((addOn) => (
					<Stack className="addon">
						<img
							className="addon-icon"
							src={`https://arma-karma.s3.eu-west-2.amazonaws.com/${addOn
								.toLowerCase()
								.replace(' ', '+')}.svg`}
							alt={addOn}
						/>
						{session?.discountPercent === undefined ? (
							<Typography variant="h3" className="basket-item-text-name">
								£2 {addOn}
							</Typography>
						) : (
							<Typography variant="h3" className="basket-item-text-name">
								<Typography variant="body1" component="span" className="old-price">
									£2
								</Typography>
								£{((1 - session?.discountPercent) * 2).toFixed(2)} {addOn}
							</Typography>
						)}
						{allowBasketEdits() ? (
							<IconButton className="addon-delete" onClick={() => deleteAddon(addOn)}>
								<DeleteIcon className="addon-delete-icon" />
							</IconButton>
						) : (
							<CheckCircle className="addon-check" />
						)}
					</Stack>
				))}

				{/* 
					Desktop
					*/}
				<Stack className="bottom-section">
					<Card className={`card ${showDiscountBox ? 'visible' : 'hidden'}`}>
						<Stack direction="row" spacing={2}>
							<TextField
								className={`discount-code`}
								variant="filled"
								label="Discount code"
								placeholder=""
								value={discountText}
								disabled={!!session?.discountCode || !allowBasketEdits()}
								InputLabelProps={{
									shrink: session?.discountCode ? true : undefined,
								}}
								onChange={(event) => setDiscountText(event.target.value)}
							/>
							<Button
								variant="contained"
								disabled={waiting || discountText === '' || !allowBasketEdits()}
								className={`discount-button`}
								onClick={handleDiscountAttempt}
								startIcon={
									waiting ? <KarmadilloSpinner color="light" size={20} /> : null
								}
							>
								Add
							</Button>
						</Stack>
						{session?.discountPercent !== undefined && (
							<Stack direction="row" className={'discount-applied'}>
								<Typography variant="body1" className="discount-applied-text">
									{session?.discountPercent * 100}% discount applied!
								</Typography>
								<TaskAltIcon className="discount-applied-icon" />
							</Stack>
						)}

						{error.errorName === 'InvalidDiscountCode' && !session?.discountCode && (
							<Stack direction="row" className={'discount-applied_failure'}>
								<Typography
									variant="body1"
									className="discount-applied-text_failure"
								>
									Failed to apply discount.
								</Typography>
								<CloseIcon className="discount-applied-icon_failure" />
							</Stack>
						)}
						{!['thank-you'].includes(session?.step ?? '') && (
							<Button
								onClick={handleRemoveDiscount}
								variant="text"
								className={`remove-discount ${
									session?.discountCode ? 'visible' : 'hidden'
								}`}
								disabled={removingDiscount || !allowBasketEdits()}
								startIcon={
									removingDiscount && (
										<KarmadilloSpinner color="green" size={15} />
									)
								}
							>
								remove discount
							</Button>
						)}
					</Card>
				</Stack>
			</Box>
		</Styles>
	);
};

const Styles = styled.div`
	position: relative;
	height: 100%;
	max-height: 100%;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	padding-bottom: ${({ theme }) => theme.spacing(4)};

	.next-step-button-icon {
		width: 28px;
		height: 28px;
	}

	.trustpilot-widget {
		padding: ${({ theme }) => theme.spacing(3, 0)};
	}

	.next-step-button-drawer {
		max-width: 250px;
		min-width: 150px;
		// margin: ${({ theme }) => theme.spacing(4)} auto;
		border-radius: 8px;
	}

	.add-more {
		color: ${({ theme }) => theme.palette.text.primary};
		justify-content: center;
		align-items: center;
		gap: ${({ theme }) => theme.spacing(2)};
		padding: ${({ theme }) => theme.spacing(1, 2)};
		background: white;
		border-radius: 8px;
		font-size: 20px;

		&-icon {
			color: ${({ theme }) => theme.palette.text.primary};
			width: 28px;
			height: 28px;
		}
	}

	.drawer-button-stack {
		max-width: 100%;
		flex-wrap: wrap;
		justify-content: center;
		gap: ${({ theme }) => theme.spacing(2)};
		margin: ${({ theme }) => theme.spacing(3, 0)};
	}

	.list-item-icon {
		color: ${({ theme }) => theme.palette.primary.main};
	}

	.remove-discount {
		display: block;
		padding: ${({ theme }) => theme.spacing(1, 2)};
		text-decoration: underline;
		font-weight: normal;
		color: ${({ theme }) => theme.palette.primary.darker};
		font-size: 16px;
		margin-left: 4px;
	}

	.discount-button {
		padding-bottom: ${({ theme }) => theme.spacing(1)};
	}

	.old-price {
		color: grey;
		opacity: 0.75;
		font-size: 14px;
		font-weight: bold;
		padding-right: ${({ theme }) => theme.spacing(1)};
		position: relative;

		&::after {
			position: absolute;
			top: 46%;
			left: -22%;
			content: '';
			width: 28px;
			height: 1.5px;
			border-radius: 3px;
			background: #414141;
			transform: rotate(-30deg);
		}
	}

	.card {
		background-color: unset;
		box-shadow: none;

		.discount-applied {
			margin-top: ${({ theme }) => theme.spacing(0)};
			padding: ${({ theme }) => theme.spacing(1)};
			justify-content: center;
			align-items: center;
			gap: ${({ theme }) => theme.spacing(2)};
			background-color: rgba(101, 167, 131, 0.2);
			border-radius: 10px;

			&-text {
				padding: 0;
				font-weight: normal;
				color: ${({ theme }) => theme.palette.primary.main};
			}

			&-icon {
				color: ${({ theme }) => theme.palette.primary.main};
			}
		}

		&.hidden {
			display: none;
		}

		padding: ${({ theme }) => theme.spacing(0, 3)};
		margin: ${({ theme }) => theme.spacing(2, 0)};
		margin-bottom: ${({ theme }) => theme.spacing(3)};

		button {
			border-radius: 10px;
			transform: translateY(1px);
		}
	}

	.addon {
		background: rgba(236, 236, 236, 0.86);
		border-radius: 50px;
		width: 100%;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: ${({ theme }) => theme.spacing(4)};
		padding: ${({ theme }) => theme.spacing(1, 2.5)};
		margin: ${({ theme }) => theme.spacing(1, 0)};

		&-title {
			align-self: flex-start;
			padding-left: ${({ theme }) => theme.spacing(1)};
			padding-top: ${({ theme }) => theme.spacing(2)};
		}

		&-icon {
			max-width: 35px;
			max-height: 50px;
		}

		&-check {
			margin-left: auto;
			color: ${({ theme }) => theme.palette.primary.main};
		}

		&-delete {
			margin-left: auto;

			&-icon {
				color: ${({ theme }) => theme.palette.primary.dark};
			}
		}
	}

	.remove-discount {
		padding-bottom: ${({ theme }) => theme.spacing(2)};

		&.hidden {
			display: none;
		}

		align-self: flex-start;
		width: 100%;
		padding-left: 0;
		text-align: left;

		&-text {
			color: ${({ theme }) => theme.palette.primary.darker};
			text-decoration: underline;
			width: 100%;
			text-align: inherit;
		}
	}

	.discount {
		width: 100%;
		justify-content: space-between;

		&-amount {
			padding: ${({ theme }) => theme.spacing(2, 4)};
		}
	}

	.discount-code,
	.discount-button {
		transition: 0.3s ease;

		&.hidden {
			opacity: 0;
			pointer-events: none;
		}
	}

	.discount-code {
		flex: 1;
	}

	.discount-button {
		border-radius: 10px;
		flex-basis: 80px;
		padding-bottom: 0;

		&:not([disabled]) {
			background-color: ${({ theme }) => theme.palette.primary.main};
		}
	}

	.what-covered-card-drawer {
		background: white;
		border-radius: 15px;
		width: max-content;
		margin: auto;
		padding: 1rem;
	}

	.what-covered-title {
		text-align: center;
		width: 100%;
		padding: ${({ theme }) => theme.spacing(1)};
	}

	.what-covered-link {
		text-decoration: underline;
		text-align: center;
		padding-bottom: ${({ theme }) => theme.spacing(3)};
		font-weight: 300;
		color: ${({ theme }) => theme.palette.primary.darker};
	}

	.bottom-section {
		padding: ${({ theme }) => theme.spacing(0, 4)};
		display: grid;
		align-items: flex-end;
		position: relative;

		&-discount {
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: ${({ theme }) => theme.spacing(4, 0, 0, 0)};

			&-text {
				color: dimgrey;
			}
		}
	}

	.discount-applied {
		margin-top: ${({ theme }) => theme.spacing(2)};
		padding: ${({ theme }) => theme.spacing(1)};
		justify-content: center;
		align-items: center;
		gap: ${({ theme }) => theme.spacing(2)};
		background-color: rgba(101, 167, 131, 0.2);
		border-radius: 10px;
		&_failure {
			margin-top: ${({ theme }) => theme.spacing(2)};
			padding: ${({ theme }) => theme.spacing(1)};
			justify-content: center;
			align-items: center;
			gap: ${({ theme }) => theme.spacing(2)};
			background-color: ${({ theme }) => theme.palette.error.light};
			border-radius: 10px;
		}

		&-text {
			padding: 0;
			font-weight: normal;
			color: ${({ theme }) => theme.palette.primary.main};
			&_failure {
				padding: 0;
				font-weight: normal;
				color: ${({ theme }) => theme.palette.error.main};
			}
		}

		&-icon {
			color: ${({ theme }) => theme.palette.primary.main};
			&_failure {
				color: ${({ theme }) => theme.palette.error.main};
			}
		}
	}
`;

export default DrawerContent;

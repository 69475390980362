import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Typography, Button, Stack } from '@mui/material';
import KarmadilloSpinner from '~/components/KarmadilloSpinner';
import { FallbackRender } from '@sentry/react';

export const ErrorBoundary: FallbackRender = ({ error, resetError }) => {
	const status = (error as any)?.response?.status;
	const data = (error as any)?.response?.data;
	const errorName = (error as any)?.response?.data?.errorName || (error as any)?.name;

	if (status === 500) {
		return <p>You're offline!</p>;
	}

	if (status === 404) {
		return (
			<>
				<Typography variant="h1">404</Typography>
				<Typography>Not found!</Typography>
				<Button onClick={() => (window.location.href = '/')}>Go home</Button>
			</>
		);
	}

	// the response json is automatically parsed to
	// `error.data`, you also have access to the status
	return (
		<div>
			<Stack
				justifyContent="center"
				alignItems="center"
				textAlign="center"
				spacing={2}
				sx={{ width: '100vw', height: '100vh' }}
			>
				<Typography variant="h1">Oh no!</Typography>
				<Typography variant="h2">An error occurred</Typography>
				<Typography>This has been reported to our team, please try again later.</Typography>
				<Button onClick={resetError}>Try again</Button>
			</Stack>
		</div>
	);
};

export default ErrorBoundary;

import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const Default: React.FC<{}> = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate('item-selection');
	});

	return <Styles></Styles>;
};

const Styles = styled.div``;

export default Default;

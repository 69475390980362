import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
	Box,
	Card,
	CircularProgress,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Skeleton,
	Stack,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { SessionContext } from '~/utils/contexts';
import api, { useGet } from '~/utils/api';
import BasketItem from '~/components/BasketItem';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { SnackbarContext } from '~/components/Snackbar/Snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { CheckCircle } from '@mui/icons-material';
import { allowBasketEdits } from '~/utils/common';
import KarmadilloSpinner from '~/components/KarmadilloSpinner';
import usePrevious from '~/hooks/usePrevious';
import { diff } from 'deep-diff';

const SideBar: React.FC<{}> = () => {
	const [showDiscountBox, setShowDiscountBox] = useState<boolean>(false);

	const { session, updateSession, totalPrice, isFetchingTotalPrice, error } =
		useContext(SessionContext);
	const [waiting, setWaiting] = useState(false);
	const [removingDiscount, setRemovingDiscount] = useState(false);
	const [discountText, setDiscountText] = useState<string | null>(null);
	const { openSnackbar } = useContext(SnackbarContext);

	const navigate = useNavigate();

	useEffect(() => {
		setDiscountText(session?.discountCode);
		setShowDiscountBox(!!session?.discountCode);
	}, [session?.discountCode]);

	const location = useLocation();

	const $trustpilot = useRef<HTMLDivElement>(null);
	useEffect(() => {
		// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
		// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
		// When it is, it will automatically load the TrustBox.
		if ((window as any).Trustpilot) {
			(window as any).Trustpilot.loadFromElement($trustpilot.current, true);
		}
	}, []);

	const handleDiscountAttempt = async () => {
		setWaiting(true);
		try {
			const session = await updateSession({
				discountCode: discountText?.toUpperCase() ?? '',
			});
		} catch (e) {
			openSnackbar(
				(e as any)?.response?.data?.errorMessage ?? (e as any)?.response?.data?.errorName,
				'error'
			);
		}
		setWaiting(false);
	};

	const handleRemoveDiscount = async () => {
		setRemovingDiscount(true);
		setWaiting(true);
		try {
			const session = await updateSession({
				discountCode: null,
			});
		} catch (e) {
			openSnackbar(
				(e as any)?.response?.data?.errorMessage ?? (e as any)?.response?.data?.errorName,
				'error'
			);
		}
		setRemovingDiscount(false);
		setDiscountText('');
		setWaiting(false);
	};

	const deleteAddon = (addOnToDelete: string) => {
		const newAddons = session?.addOns.filter((addOn) => addOn !== addOnToDelete);
		void updateSession({ addOns: newAddons });
	};

	const listItems = [
		{ primary: 'Accidental Damage', secondary: '' },
		{ primary: 'Loss', secondary: '(Bikes and laptops excluded)' },
		{ primary: 'Theft', secondary: '' },
		{ primary: 'Mechanical Breakdown', secondary: '' },
		{ primary: '60 Days Abroad', secondary: '' },
		{ primary: '14-day money back guarantee', secondary: '' },
	];

	return (
		<Styles>
			<Box className="content">
				<Stack className="total">
					<Stack>
						<Typography variant="h1" className="price-stack-price">
							{isFetchingTotalPrice ? (
								<Stack fontFamily="gilroy" direction="row" spacing={0.5}>
									£<Skeleton variant="text" width={30} />.
									<Skeleton variant="text" width={60} />
								</Stack>
							) : session?.products?.length === 0 ? (
								<Box fontFamily="gilroy">£0.00</Box>
							) : (
								<Stack>
									<Box fontFamily="gilroy">
										£{totalPrice?.quote?.toFixed(2) ?? '0.00'}
										<Typography
											component="span"
											variant="h6"
											textTransform="lowercase"
											sx={{ mr: 1 }}
										>
											/month
										</Typography>
									</Box>
								</Stack>
							)}
						</Typography>
						<Typography pl={1} pt={0.5} className="ipt">
							inclusive of IPT
						</Typography>
					</Stack>

					{!['thank-you', 'checkout'].includes(
						location?.pathname?.split('/').pop() ?? ''
					) && (
						<Box className="basket-wrapper">
							<ShoppingBasketIcon className="basket-icon" />
							<Typography
								variant="body2"
								component="span"
								className="basket-wrapper-number"
							>
								{session?.products?.length ?? 0}
							</Typography>
						</Box>
					)}
				</Stack>

				<Box mb={4} className="products-container" maxWidth="100%">
					{session?.products?.map((item, index) => {
						return <BasketItem key={index} item={item} category="" edit />;
					})}
				</Box>
				<Stack spacing={6}>
					{!location?.pathname?.includes('item-selection') && (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							spacing={2}
						>
							<Typography variant="subtitle1" className="add-more-text">
								Add more
							</Typography>
							<IconButton
								className="icon-wrapper add-more-icon"
								onClick={(e) => {
									e.preventDefault();
									navigate('/');
								}}
							>
								<AddIcon className="icon-wrapper-icon" />
							</IconButton>
						</Stack>
					)}

					<Stack justifyContent="center" direction="row" alignItems="center">
						<Typography variant="h6" className="">
							Got a discount code?
						</Typography>

						<Switch
							value={showDiscountBox}
							checked={showDiscountBox}
							disabled={!!session?.discountCode || !allowBasketEdits()}
							onChange={(event, checked) => setShowDiscountBox(checked)}
						/>
					</Stack>
					<Typography
						className="what-covered-link"
						component="a"
						href="https://armakarma.insure/documents"
						target={'_blank'}
						referrerPolicy={'no-referrer'}
					>
						Cover Summary
					</Typography>

					{/*TrustBox widget - Micro Star*/}
					<div
						className="trustpilot-widget"
						ref={$trustpilot}
						data-locale="en-GB"
						data-template-id="5419b732fbfb950b10de65e5"
						data-businessunit-id="5e7b7d7e193e49000122ab0e"
						data-style-height="24px"
						data-style-width="100%"
						data-theme="light"
					>
						<a
							href="https://uk.trustpilot.com/review/armakarma.insure"
							target="_blank"
							rel="noopener"
						>
							Trustpilot
						</a>
					</div>
					{/*End TrustBox widget*/}
				</Stack>

				{(session?.addOns?.length ?? 0) > 0 && (
					<Typography variant="h3" className="addon-title">
						Add-ons:{' '}
					</Typography>
				)}
				{session?.addOns?.map((addOn) => (
					<Stack className="addon">
						<img
							className="addon-icon"
							src={`https://arma-karma.s3.eu-west-2.amazonaws.com/${addOn
								.toLowerCase()
								.replace(' ', '+')}.svg`}
							alt={addOn}
						/>
						{session?.discountPercent === undefined ? (
							<Typography variant="h3" className="basket-item-text-name">
								£2 {addOn}
							</Typography>
						) : (
							<Typography variant="h3" className="basket-item-text-name">
								<Typography variant="body1" component="span" className="old-price">
									£2
								</Typography>
								£{((1 - session?.discountPercent) * 2).toFixed(2)} {addOn}
							</Typography>
						)}
						{allowBasketEdits() ? (
							<IconButton className="addon-delete" onClick={() => deleteAddon(addOn)}>
								<DeleteIcon className="addon-delete-icon" />
							</IconButton>
						) : (
							<CheckCircle className="addon-check" />
						)}
					</Stack>
				))}

				{/* 
					Desktop
					*/}
				<Stack className="bottom-section">
					<Card className={`card ${showDiscountBox ? 'visible' : 'hidden'}`}>
						<Stack direction="row" spacing={2}>
							<TextField
								className={`discount-code`}
								variant="filled"
								label="Discount code"
								placeholder=""
								value={discountText}
								disabled={!!session?.discountCode || !allowBasketEdits()}
								InputLabelProps={{
									shrink: session?.discountCode ? true : undefined,
								}}
								onChange={(event) => setDiscountText(event.target.value)}
							/>
							<Button
								variant="contained"
								disabled={waiting || discountText === '' || !allowBasketEdits()}
								className={`discount-button`}
								onClick={handleDiscountAttempt}
								startIcon={
									waiting ? <KarmadilloSpinner color="light" size={20} /> : null
								}
							>
								Add
							</Button>
						</Stack>
						{session?.discountPercent !== undefined && (
							<Stack direction="row" className={'discount-applied'}>
								<Typography variant="body1" className="discount-applied-text">
									{session?.discountPercent * 100}% discount applied!
								</Typography>
								<TaskAltIcon className="discount-applied-icon" />
							</Stack>
						)}

						{error.errorName === 'InvalidDiscountCode' && !session?.discountCode && (
							<Stack direction="row" className={'discount-applied_failure'}>
								<Typography
									variant="body1"
									className="discount-applied-text_failure"
								>
									Failed to apply discount.
								</Typography>
								<CloseIcon className="discount-applied-icon_failure" />
							</Stack>
						)}
						{!['thank-you'].includes(session?.step ?? '') && (
							<Button
								onClick={handleRemoveDiscount}
								variant="text"
								className={`remove-discount ${
									session?.discountCode ? 'visible' : 'hidden'
								}`}
								disabled={removingDiscount || !allowBasketEdits()}
								startIcon={
									removingDiscount && (
										<KarmadilloSpinner color="green" size={15} />
									)
								}
							>
								remove discount
							</Button>
						)}
					</Card>
				</Stack>
			</Box>
		</Styles>
	);
};

const Styles = styled.div`
	.products-container {
		margin-top: ${({ theme }) => theme.spacing(5)};
	}
	padding: ${({ theme }) => theme.spacing(3)};
	padding-bottom: ${({ theme }) => theme.spacing(2)};
	flex: 1;

	.list-item-icon {
		color: ${({ theme }) => theme.palette.primary.main};
	}

	.basket-item-text-name {
		font-size: 16px;
	}
	.trustpilot-widget {
		padding: ${({ theme }) => theme.spacing(1.5, 0)};
	}

	.card {
		background-color: unset;
		box-shadow: none;

		.discount-applied {
			margin-top: ${({ theme }) => theme.spacing(2)};
			padding: ${({ theme }) => theme.spacing(1)};
			justify-content: center;
			align-items: center;
			gap: ${({ theme }) => theme.spacing(2)};
			background-color: rgba(101, 167, 131, 0.2);
			border-radius: 10px;
			&_failure {
				margin-top: ${({ theme }) => theme.spacing(2)};
				padding: ${({ theme }) => theme.spacing(1)};
				justify-content: center;
				align-items: center;
				gap: ${({ theme }) => theme.spacing(2)};
				background-color: ${({ theme }) => theme.palette.error.light};
				border-radius: 10px;
			}

			&-text {
				padding: 0;
				font-weight: normal;
				color: ${({ theme }) => theme.palette.primary.main};
				&_failure {
					padding: 0;
					font-weight: normal;
					color: ${({ theme }) => theme.palette.error.main};
				}
			}

			&-icon {
				color: ${({ theme }) => theme.palette.primary.main};
				&_failure {
					color: ${({ theme }) => theme.palette.error.main};
				}
			}
		}

		&.hidden {
			display: none;
		}

		padding: ${({ theme }) => theme.spacing(2, 3)};
		margin: ${({ theme }) => theme.spacing(0)};

		button {
			border-radius: 10px;
			transform: translateY(-3px);
		}
	}

	.addon {
		background: rgba(236, 236, 236, 0.86);
		border-radius: 50px;
		width: 100%;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: ${({ theme }) => theme.spacing(4)};
		padding: ${({ theme }) => theme.spacing(1, 2.5)};
		margin: ${({ theme }) => theme.spacing(1, 0)};

		.old-price {
			color: grey;
			opacity: 0.75;
			font-size: 14px;
			font-weight: bold;
			padding-right: ${({ theme }) => theme.spacing(1)};
			position: relative;

			&::after {
				position: absolute;
				top: 46%;
				left: -22%;
				content: '';
				width: 28px;
				height: 1.5px;
				border-radius: 3px;
				background: #414141;
				transform: rotate(-30deg);
			}
		}

		&-check {
			margin-left: auto;
			color: ${({ theme }) => theme.palette.primary.main};
		}

		&-title {
			align-self: flex-start;
			padding-left: ${({ theme }) => theme.spacing(1)};
			padding-top: ${({ theme }) => theme.spacing(2)};
		}

		&-icon {
			max-width: 35px;
			max-height: 50px;
		}

		&-delete {
			margin-left: auto;

			&-icon {
				color: ${({ theme }) => theme.palette.primary.dark};
			}
		}
	}

	.what-covered-title {
		text-align: center;
		width: 100%;
		padding: ${({ theme }) => theme.spacing(1)};
	}

	.what-covered-link {
		text-decoration: underline;
		text-align: center;
		font-weight: 300;
		color: ${({ theme }) => theme.palette.primary.darker};
	}

	.content {
		height: 100%;
		margin: ${({ theme }) => theme.spacing(-3)};
		margin-bottom: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: ${({ theme }) => theme.spacing(2)};
		padding-bottom: 0;
	}

	.bottom-section-discount {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		padding-top: ${({ theme }) => theme.spacing(2)};
	}

	.discount-button,
	.discount-code,
	.remove-discount {
		transition: 0.3s ease;
		padding-bottom: ${({ theme }) => theme.spacing(1)};
		margin-top: ${({ theme }) => theme.spacing(0)};

		&.hidden {
			opacity: 0;
			visibility: hidden;
			display: none;
			pointer-events: none;
		}
	}

	.remove-discount {
		padding: ${({ theme }) => theme.spacing(1)};
		text-decoration: underline;
		font-weight: normal;
		color: ${({ theme }) => theme.palette.primary.darker};
		font-size: 16px;
	}

	.discount-button {
		padding-bottom: ${({ theme }) => theme.spacing(1)};
	}

	.price-stack {
		flex-direction: row;
		width: 100%;
		justify-content: space-around;
		padding-top: ${({ theme }) => theme.spacing(4)};
		align-items: center;
	}

	.total {
		flex-direction: row;
		align-items: center;

		.price-stack-price {
			padding: 0 0.5rem;
		}

		.ipt {
			height: 0;
			color: #aeacac;
			font-weight: bold;
		}
	}

	.basket-icon {
		font-size: 40px;
		color: ${({ theme }) => theme.palette.primary.dark};
	}

	.icon-wrapper {
		background-color: ${({ theme }) => theme.palette.primary.dark};

		&-icon {
			color: white;
		}
	}

	.add-more {
		align-items: center;
		align-self: flex-start;
		gap: ${({ theme }) => theme.spacing(2)};
		padding: ${({ theme }) => theme.spacing(0, 4)};
		width: 100%;
		justify-content: center;
	}

	.basket-wrapper {
		position: relative;

		&-number {
			width: 24px;
			font-weight: bold;
			aspect-ratio: 1;
			color: white;
			border-radius: 50%;
			font-size: 16px;
			text-align: center;
			background-color: ${({ theme }) => theme.palette.primary.main};
			position: absolute;
			bottom: -5%;
			right: -18%;
		}
	}
`;

export default SideBar;

import { css } from '@emotion/react';
import { Theme } from '@mui/material';

const InputLabel = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiInputLabel: {
			styleOverrides: {
				root: css`
					text-transform: capitalize;
					font-weight: 700;
				`,
			},
		},
	};
};

export default InputLabel;

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Checkbox, InputAdornment, Stack, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { useSession } from '~/utils/contexts';
import { MyCheckbox, MyTextInput } from '~/components/CustomInputElements';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { addHours, differenceInYears, isValid, parse } from 'date-fns';

export const EmailPhoneForm = () => {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const { currentFormRef, updateSession, setAllowProgress, handleSubmitRef, session } =
		useSession();

	const handleSubmit = async (values) => {
		await updateSession({
			details: {
				...(session?.details ?? {}),
				email: values.email,
				phoneNumber: { countryCode: '44', phone: values.phone },
				agreedToC: values.acceptedTerms,
				agreedToOffers: values.acceptedOffers,
			},
		});
	};

	const disableEvents = (e) => {
		e.preventDefault();
	};

	return (
		<Styles>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Formik
					enableReinitialize={true}
					innerRef={currentFormRef}
					initialValues={{
						email: session?.details?.email,
						confirmEmail: '',
						acceptedTerms: session?.details?.agreedToC ?? false, // added for our checkbox
						acceptedOffers: session?.details?.agreedToOffers ?? false,
						phone: session?.details?.phoneNumber?.phone,
					}}
					validationSchema={Yup.object({
						email: Yup.string().email('Invalid email address').required('Required'),
						confirmEmail: Yup.string()
							.oneOf([Yup.ref('email'), null], 'Emails must match')
							.required('Required'),
						phone: Yup.string()
							.matches(phoneRegExp, 'Invalid phone number')
							.required('Required')
							.max(11, 'That phone number is too long.')
							.min(8, 'That phone number is too short'),
						acceptedTerms: Yup.boolean()
							.required('Required')
							.oneOf([true], 'Please accept the terms before continuing'),
						acceptedOffers: Yup.boolean(),
					})}
					onSubmit={handleSubmit}
				>
					{(formik) => {
						let {
							errors,
							touched,
							isValid: reportedErrors,
							dirty,
							values,
							setFieldValue,
						} = formik;

						if (
							session?.details?.email &&
							session?.details.agreedToC &&
							session?.details?.phoneNumber
						) {
							dirty = true;
						}
						useEffect(() => {
							setAllowProgress(dirty && reportedErrors);
						}, [reportedErrors, dirty]);

						// Control the next button internally
						handleSubmitRef!.current = async () => {
							if (!reportedErrors) return false;
							try {
								await handleSubmit(values);
							} catch (e) {
								return false;
							}
							return true;
						};

						return (
							<Form className="form">
								<MyTextInput
									label="Email Address"
									name="email"
									type="email"
									placeholder=""
									InputProps={{
										endAdornment:
											!errors.email &&
											values.email !== undefined &&
											values.email.length > 0 ? (
												<DoneIcon sx={{ color: 'primary.main' }} />
											) : (
												values.confirmEmail.length > 0 && (
													<CloseIcon sx={{ color: 'error.main' }} />
												)
											),
									}}
								/>
								<MyTextInput
									label="Confirm Email Address"
									name="confirmEmail"
									type="email"
									placeholder=""
									onCut={disableEvents}
									onCopy={disableEvents}
									onPaste={disableEvents}
									InputProps={{
										endAdornment:
											!errors.confirmEmail &&
											values.confirmEmail !== undefined &&
											values.confirmEmail.length > 0 ? (
												<DoneIcon sx={{ color: 'primary.main' }} />
											) : (
												values.confirmEmail.length > 0 && (
													<CloseIcon sx={{ color: 'error.main' }} />
												)
											),
									}}
								/>

								<MyTextInput
									label="Phone number"
									name="phone"
									type="tel"
									placeholder=""
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">+44</InputAdornment>
										),
									}}
								/>

								<MyCheckbox name="acceptedTerms">
									I accept the{' '}
									<a
										className="terms-link"
										href="https://armakarma.insure/terms-conditions"
										target="_blank"
										style={{ whiteSpace: 'nowrap' }}
									>
										terms and conditions
									</a>
									&nbsp;and&nbsp;
									<a
										className="terms-link"
										href="https://armakarma.insure/privacy-policy-2"
										target="_blank"
										style={{ whiteSpace: 'nowrap' }}
									>
										privacy policy
									</a>
								</MyCheckbox>
								<MyCheckbox name="acceptedOffers">
									I’m happy to receive rewards, offers and news from Arma Karma
								</MyCheckbox>
							</Form>
						);
					}}
				</Formik>
			</LocalizationProvider>
		</Styles>
	);
};

const Styles = styled.div`
	.form {
		display: flex;
		flex-direction: row;
	}

	.terms-link {
		color: ${({ theme }) => theme.palette.primary.darker};
		text-decoration: underline;
		transition: 0.2s ease;

		&:hover {
			color: ${({ theme }) => theme.palette.primary.main};
		}
	}

	.input-box {
		margin: 8px 0px;
		.MuiInputAdornment-root {
			margin-right: 0px !important;
		}
	}
`;

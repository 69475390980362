import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Checkbox, InputAdornment, Stack, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { useSession } from '~/utils/contexts';
import { MyCheckbox, MyTextInput } from '~/components/CustomInputElements';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import { addHours, differenceInYears, isValid, parse } from 'date-fns';

export const NameForm = () => {
	const { currentFormRef, updateSession, setAllowProgress, handleSubmitRef, session } =
		useSession();

	const handleSubmit = async (values) => {
		await updateSession({
			details: {
				...(session?.details ?? {}),
				firstName: values.firstName,
				lastName: values.lastName,
				dob:
					new Date(
						new Date(values.dob).getTime() -
							new Date(values.dob).getTimezoneOffset() * 60000
					).toString() ?? new Date(Date.now()),
			},
		});
	};

	return (
		<Styles>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Formik
					enableReinitialize={true}
					innerRef={currentFormRef}
					initialValues={{
						firstName: session?.details?.firstName,
						lastName: session?.details?.lastName,
						dob: session?.details?.dob
							? moment.utc(session?.details?.dob)
							: moment.utc('01/01/2000'),
					}}
					validationSchema={Yup.object({
						firstName: Yup.string()
							.max(15, 'Must be 15 characters or less')
							.required('Required'),
						lastName: Yup.string()
							.max(20, 'Must be 20 characters or less')
							.required('Required'),
						dob: Yup.date()
							.nullable()
							.typeError('The date format is wrong please use DD/MM/YYYY')
							.required('Required')
							.test('dob', 'You must be older than 18', function (value, ctx) {
								if (!value) return ctx.createError();
								try {
									const validDate = new Date();
									const valid = differenceInYears(validDate, value) >= 18;
									return !valid ? ctx.createError() : valid;
								} catch (e) {
									console.error(e);
									return ctx.createError();
								}
							})
							.test(
								'centenary',
								'Age can not be more than 100',
								function (value, ctx) {
									if (!value) return ctx.createError();
									try {
										const validDate = new Date();
										const valid = differenceInYears(validDate, value) <= 100;
										return !valid ? ctx.createError() : valid;
									} catch (e) {
										console.error(e);
										return ctx.createError();
									}
								}
							),
					})}
					onSubmit={handleSubmit}
				>
					{(formik) => {
						let {
							errors,
							touched,
							isValid: reportedErrors,
							dirty,
							values,
							setFieldValue,
						} = formik;
						if (
							session?.details?.firstName &&
							session?.details?.lastName &&
							session?.details?.dob
						) {
							dirty = true;
						}

						useEffect(() => {
							setAllowProgress(dirty && reportedErrors);
						}, [reportedErrors, dirty]);

						// Control the next button internally
						handleSubmitRef!.current = async () => {
							if (!reportedErrors) return false;

							try {
								await handleSubmit(values);
							} catch (e) {
								return false;
							}
							return true;
						};

						return (
							<Form className="form">
								<MyTextInput
									label="First Name"
									name="firstName"
									type="text"
									placeholder=""
								/>

								<MyTextInput
									label="Last Name"
									name="lastName"
									type="text"
									placeholder=""
								/>

								<DatePicker
									openTo="year"
									className="input-box"
									label="Date of Birth"
									inputFormat="dd/MM/yyyy"
									value={values.dob}
									onChange={(value, t) => {
										if (value) {
											if (
												value.getFullYear() >= 50 &&
												value.getFullYear() <= 99
											) {
												value.setFullYear(value.getFullYear() + 1900);
											} else if (value.getFullYear() < 9) {
												value.setFullYear(value.getFullYear() + 2000);
											}
										}
										setFieldValue('dob', value);
									}}
									desktopModeMediaQuery="@media (min-width: 0px)"
									renderInput={(params) => (
										<TextField
											fullWidth
											{...params}
											error={!!errors['dob']}
											helperText={
												errors['dob']
													? // @ts-ignore
													  errors['dob']
													: params?.helperText
											}
										/>
									)}
									maxDate={moment().subtract(18, 'years')}
									minDate={moment('01/01/1920')}
								/>
							</Form>
						);
					}}
				</Formik>
			</LocalizationProvider>
		</Styles>
	);
};

const Styles = styled.div`
	.form {
		display: flex;
		flex-direction: row;
	}

	.terms-link {
		color: ${({ theme }) => theme.palette.primary.darker};
		text-decoration: underline;
		transition: 0.2s ease;

		&:hover {
			color: ${({ theme }) => theme.palette.primary.main};
		}
	}

	.input-box {
		margin: 8px 0px;
		.MuiInputAdornment-root {
			margin-right: 0px !important;
		}
	}
`;

import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Checkbox, Stack, TextField, Typography } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { SessionContext } from '~/utils/contexts';
import { DetailsForm } from '~/components/CustomForm';
import { NameForm } from './NameForm';

const YourDetails: React.FC<{}> = () => {
	const [termsAndPrivacy, setTermsAndPrivacy] = useState<boolean>(false);
	const [offersAndNews, setOffersAndNews] = useState<boolean>(false);

	return (
		<Styles>
			<NameForm />
		</Styles>
	);
};

const Styles = styled.div`
	.form {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		&-field {
			margin: ${({ theme }) => theme.spacing(1, 0)};
			width: 100%;

			&.firstname,
			&.lastname {
				width: 49%;
			}

			${({ theme }) => theme.breakpoints.up('sm')} {
				width: 49%;
			}
		}
	}

	.checkbox {
		flex-direction: row;
		align-items: center;
		padding-top: ${({ theme }) => theme.spacing(1)};

		.terms {
			&-text {
				a {
					color: ${({ theme }) => theme.palette.common.black};
					opacity: 0.6;
				}
			}
		}
	}
`;

export default YourDetails;

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { StepConnector, stepConnectorClasses, StepIconProps } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useSession } from '~/utils/contexts';
import { useEffect, useState } from 'react';

export interface Stage {
	name: string;
	isHalf: boolean;
}

const CustomStepper: React.FC<{ location: Location }> = ({ location }) => {
	const CustomConnector = styled(StepConnector)(({ theme }) => ({
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 10,
			left: 'calc(-50% + 16px)',
			right: 'calc(50% + 16px)',
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				borderColor: theme.palette.primary.main,
			},
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				borderColor: theme.palette.primary.main,
			},
		},

		['&.Mui-active.half']: {
			[`& .${stepConnectorClasses.line}`]: {
				borderColor: '#C0C0C0',
				position: 'relative',
			},
			[`& .${stepConnectorClasses.line}::after`]: {
				content: "''",
				position: 'absolute',
				width: '50%',
				height: '4px',
				borderRadius: '2px',
				background: theme.palette.primary.main,
				top: '-4px',
			},
		},

		[`& .${stepConnectorClasses.line}`]: {
			borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
			borderTopWidth: 4,
			borderRadius: 5,
		},
	}));

	const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
		({ theme, ownerState }) => ({
			color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
			display: 'flex',
			height: 22,
			alignItems: 'center',
			...(ownerState.active && {
				color: '#784af4',
			}),
			'& .QontoStepIcon-completedIcon': {
				color: '#784af4',
				zIndex: 1,
				fontSize: 18,
			},
			'& .QontoStepIcon-circle': {
				width: 8,
				height: 8,
				borderRadius: '50%',
				backgroundColor: 'currentColor',
			},
		})
	);

	const stages = [
		[{ name: 'add-on', isHalf: true }],
		[
			{ name: 'details', isHalf: true },
			{ name: 'address', isHalf: false },
		],
		[
			{ name: 'disclaimers', isHalf: true },
			{ name: 'charity', isHalf: true },
		],
		[
			{ name: 'checkout', isHalf: false },
			{ name: 'thank-you', isHalf: false },
		],
	];

	const [currentStage, setCurrentStage] = useState<Stage>(stages[0][0]);

	useEffect(() => {
		for (let i = 0; i < stages.length; i++) {
			for (let j = 0; j < stages[i].length; j++) {
				if (location.pathname.includes(stages[i][j].name)) {
					setCurrentStage(stages[i][j]);
				}
			}
		}
	}, [location]);

	function CustomStepIcon(props: StepIconProps) {
		const { active, completed, className } = props;

		return (
			<CustomStepIconRoot ownerState={{ active }} className={className}>
				{completed ? (
					<Box className="step-icon outer">
						<Box className="step-icon inner"></Box>
					</Box>
				) : (
					<Box className="step-icon outer grey">
						<Box className="step-icon inner grey"></Box>
					</Box>
				)}
			</CustomStepIconRoot>
		);
	}

	const getCurrentStage = (): number => {
		for (let i = 0; i < stages.length; i++) {
			for (let j = 0; j < stages[i].length; j++) {
				if (stages[i][j].name === currentStage.name) {
					return i + 1;
				}
			}
		}
		return 0;
	};

	return (
		<Styled>
			{!location.pathname.includes('item-selection') ? (
				<Box sx={{ width: '100%' }} className="wrapper">
					<Stepper
						activeStep={getCurrentStage()}
						connector={
							<CustomConnector className={currentStage.isHalf ? 'half' : ''} />
						}
					>
						{stages.map((label, index) => {
							const stepProps: { completed?: boolean } = {};
							const labelProps: {
								optional?: React.ReactNode;
							} = {};
							return (
								<Step key={index} {...stepProps}>
									<StepLabel StepIconComponent={CustomStepIcon} {...labelProps} />
								</Step>
							);
						})}
					</Stepper>
				</Box>
			) : (
				<></>
			)}
		</Styled>
	);
};

export default CustomStepper;

const Styled = styled.div`
	.wrapper {
		padding: ${({ theme }) => theme.spacing(4, 4, 1, 4)};

		${({ theme }) => theme.breakpoints.down('md')} {
			padding: ${({ theme }) => theme.spacing(2, 4, 1, 4)};
		}
	}

	.step-icon {
		border: solid 6px ${({ theme }) => theme.palette.primary.main};
		border-radius: 50%;
		display: grid;
		place-items: center;

		.step-icon {
			border: solid 6px ${({ theme }) => theme.palette.primary.main};
			border-radius: 50%;
			display: grid;
			place-items: center;
		}

		&.grey {
			background: #c0c0c0 !important;
		}

		&.outer.grey {
			border-color: #c0c0c0 !important;
		}

		.step-icon.outer {
			height: 32px;
			width: 32px;
		}

		.step-icon.inner {
			width: 18px;
			height: 18px;
			border: solid 4px ${({ theme }) => theme.palette.common.white};
			background: ${({ theme }) => theme.palette.primary.main};
		}
	}
`;

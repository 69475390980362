import React, { useEffect } from 'react';
import { useField } from 'formik';
import { Checkbox, Stack, TextField, TextFieldProps } from '@mui/material';
import styled from '@emotion/styled';

export const MyTextInput: React.FC<{ label: string; styleClass?: string } & TextFieldProps> = ({
	label,
	styleClass,
	...props
}) => {
	// useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
	// which we can spread on <input>. We can use field meta to show an error
	// message if the field is invalid and it has been touched (i.e. visited)
	const [field, meta, helpers] = useField(props);
	useEffect(() => {
		if ((document.querySelector(`input[name="${props.name}"]`) as HTMLInputElement)?.value) {
			if (!field.value) {
				helpers.setValue(
					(document.querySelector(`input[name="${props.name}"]`) as HTMLInputElement)
						?.value || ''
				);
			}
		}
	});

	return (
		<Styles
			className={`text custom-text-field ${
				label.includes('Name') ? 'short' : ''
			} ${styleClass}`}
		>
			<TextField
				label={label}
				variant="filled"
				className={`text-input`}
				{...field}
				{...props}
				InputLabelProps={{
					shrink: field.value ? true : undefined,
				}}
			/>
			{meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
		</Styles>
	);
};

export const MyCheckbox = ({ children, ...props }) => {
	// React treats radios and checkbox inputs differently other input types, select, and textarea.
	// Formik does this too! When you specify `type` to useField(), it will
	// return the correct bag of props for you -- a `checked` prop will be included
	// in `field` alongside `name`, `value`, `onChange`, and `onBlur`
	const [field, meta] = useField({ ...props, type: 'checkbox' });
	return (
		<Styles className="checkbox">
			<label className="checkbox-input">
				<Checkbox {...field} {...props} />
				{children}
			</label>
			{meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
		</Styles>
	);
};

export const MySelect = ({ label, ...props }) => {
	const [field, meta] = useField(props);
	return (
		<Styles>
			<label htmlFor={props.id || props.name}>{label}</label>
			<select {...field} {...props} />
			{meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
		</Styles>
	);
};

const Styles = styled.div`
	&.text {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&.custom-text-field {
		width: 100%;

		&:not(.no-margin) {
			margin: ${({ theme }) => theme.spacing(1, 0)};
		}

		&.short {
			width: 48%;
			display: inline-block;

			* {
				width: 100%;
			}

			&:nth-of-type(even) {
				margin-left: 4%;
			}
		}
	}

	.checkbox {
		margin-top: ${({ theme }) => theme.spacing(1)};
	}

	.error {
		color: #db124b;
		font-size: 14px;
		margin-top: ${({ theme }) => theme.spacing(0.5)};
		margin-left: ${({ theme }) => theme.spacing(1)};
	}
`;

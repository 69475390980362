import React from 'react';
import styled from '@emotion/styled';
import DisclaimerAccordians from '~/components/DisclaimerAccordians';
import { Box, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

const GeneralDisclaimers: React.FC<{}> = () => {
	const generalDisclaimers = [
		{
			title: 'Disclaimer One',
			body: "I've not been bankrupt, served with CCJs/IVAs or had arrangements with creditors within the last seven years.",
			accepted: false,
			expanded: true,
			tooltipText:
				'CCJ/IVA - CCJ (Country Court Judgement), IVA (Individual Voluntary Arrangement).',
		},
		{
			title: 'Disclaimer Two',
			body: "I've not been declined/refused insurance or had a policy cancelled by an insurer.",
			accepted: false,
			expanded: false,
		},
		{
			title: 'Disclaimer Three',
			body: "I don't have unspent criminal convictions.",
			accepted: false,
			expanded: false,
			tooltipText:
				'Any criminal convictions that would still show on public record other than speeding fines.',
		},
		{
			title: 'Disclaimer Four',
			body: "I haven't made an insurance claim for the type of items that are to be covered in the last 5 years.",
			accepted: false,
			expanded: false,
		},
		{
			title: 'Disclaimer Five',
			body: 'I understand that signing up and claiming for an item that is already lost, damaged or stolen is insurance fraud and often leads to a criminal conviction.',
			accepted: false,
			expanded: false,
		},

		{
			title: 'Disclaimer Six',
			body: 'I have taken reasonable care to provide accurate and honest information. I understand that failure to do so may invalidate any claim and/or the policy.',
			accepted: false,
			expanded: false,
		},
	];

	const scrollable = useOutletContext();

	return (
		<Styles>
			<DisclaimerAccordians
				disclaimersPage={true}
				scrollable={scrollable}
				accordianData={generalDisclaimers}
			/>
			<Box mt={2} />
			<Typography px={2} sx={{ color: 'error.main' }}>
				Please note we have an initial 14-day no claims period to keep prices low for true
				and honest subscribers.
			</Typography>
		</Styles>
	);
};

const Styles = styled.div``;

export default GeneralDisclaimers;

import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Checkbox, InputAdornment, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSession } from '~/utils/contexts';
import { MyCheckbox, MyTextInput } from '~/components/CustomInputElements';
import { Link, useNavigate } from 'react-router-dom';
import Fetchify from '~/components/Fetchify';
import { LocalHospital } from '@mui/icons-material';

const ManualAddressEntry: React.FC<{}> = () => {
	const { currentFormRef, setAllowProgress, updateSession } = useSession();

	const navigate = useNavigate();

	return (
		<Styles>
			<Formik
				innerRef={currentFormRef}
				initialValues={{
					postcode: '',
					ukResident: false,
				}}
				validationSchema={Yup.object({
					postcode: Yup.string()
						.max(8, 'Must be 8 characters or less')
						.required('Required'),
					address1: Yup.string()
						.max(30, 'Must be 30 characters or less')
						.required('Required'),
					address2: Yup.string().max(30, 'Must be 30 characters or less'),
					county: Yup.string()
						.max(20, 'Must be 20 characters or less')
						.required('Required'),
					city: Yup.string()
						.max(20, 'Must be 20 characters or less')
						.required('Required'),
					ukResident: Yup.boolean().isTrue(
						'Sorry, You must be a UK resident to use this service.'
					),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					let format = false;
					if (!values?.postcode.includes(' ')) format = true;

					const formattedPostcodeEnd = values?.postcode.substr(
						values?.postcode.length - 3
					);
					const startIndex = values?.postcode.length - 3;
					const formattedPostCodeStart = values?.postcode.slice(0, startIndex);

					const formattedPostcode = `${formattedPostCodeStart} ${formattedPostcodeEnd}`;

					await updateSession({
						address: {
							postcode: format ? formattedPostcode : values?.postcode,
							firstLine: values?.address1,
							secondLine: values?.address2 ?? '',
							city: values?.city,
							county: values?.county,
							externalApproved: true,
						},
					});
				}}
			>
				{(formik) => {
					const { errors, touched, isValid, dirty, setFieldValue } = formik;

					useEffect(() => {
						setAllowProgress(dirty && isValid);
					}, [isValid, dirty]);

					return (
						<Form className="form">
							<MyTextInput
								label="Address line 1"
								name="address1"
								type="text"
								placeholder=""
							/>
							<MyTextInput
								label="Address line 2"
								name="address2"
								type="text"
								placeholder=""
							/>
							<MyTextInput
								label="County"
								name="county"
								type="text"
								placeholder=""
								styleClass="short"
							/>
							<MyTextInput
								label="City"
								name="city"
								type="text"
								placeholder=""
								styleClass="short"
							/>
							<MyTextInput
								label="Postal code"
								name="postcode"
								type="text"
								placeholder=""
							/>
							<MyCheckbox name="ukResident">
								<Typography
									sx={{ my: 4 }}
									className="accordian-title"
									variant="body1"
									component="span"
								>
									I confirm that I am a UK resident
								</Typography>
							</MyCheckbox>
						</Form>
					);
				}}
			</Formik>

			<Link to="/address" className="back">
				<Typography variant="body1" className="back-text">
					Back to postcode lookup
				</Typography>
			</Link>
		</Styles>
	);
};

const Styles = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 !important;

	.checkbox {
		margin-top: ${({ theme }) => theme.spacing(1)};
	}

	.back {
		width: auto;
		margin-top: ${({ theme }) => theme.spacing(3)};

		&-text {
			opacity: 0.75;
			text-decoration: underline;
			color: ${({ theme }) => theme.palette.primary.darker};
		}
	}

	.form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 700px;

		${({ theme }) => theme.breakpoints.up('md')} {
			min-width: 500px;
		}
	}
`;

export default ManualAddressEntry;

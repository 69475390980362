import React, { lazy } from 'react';
import type { PreRoute } from '~/models/route';
import Layout from '~/components/Layout';
import SelectCharity from '~/components/SelectCharity';
import DisclaimerAccordians from '~/components/DisclaimerAccordians';
import AddOns from '~/components/AddOns';
import ChooseStartDate from '~/components/ChooseStartDate';
import ThankYou from '~/theme/components/ThankYou';
import YourDetails from '~/components/YourDetails';
import YourAddress from '~/components/YourAddress';
import Checkout from '~/components/Checkout';
import Default from '~/components/Default';
import ManualAddressEntry from '~/components/ManualAddressEntry';
import VerifyPhone from '~/components/VerifyPhone';
import GeneralDisclaimers from '~/components/GeneralDisclaimers';
import Name from '~/components/Details/Name';
import Address from '~/components/Details/Address';
import EmailPhone from '~/components/Details/EmailPhone';

// const Layout = lazy(() => import('../components/Layout'))
const Home = lazy(() => import('../pages/index'));
const Fraud = lazy(() => import('../pages/fraud'));
const Private = lazy(() => import('../components/Private'));

const routes: PreRoute[] = [
	{
		path: '/unavailable',
		element: <Fraud />,
	},
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '/',
				element: <Default />,
			},
			{
				path: '/item-selection',
				element: <Home />,
			},
			{
				path: '/add-on',
				element: <AddOns />,
			},
			{
				path: '/charity',
				element: <SelectCharity />,
			},
			{
				path: '/disclaimers',
				element: <GeneralDisclaimers />,
			},
			{
				path: '/details',
				element: <YourDetails />,
			},
			{
				path: '/name',
				element: <Name />,
			},
			{
				path: '/email-phone',
				element: <EmailPhone />,
			},
			{
				path: '/verify-phone',
				element: <VerifyPhone />,
			},
			{
				path: '/address',
				element: <YourAddress />,
			},
			// {
			// 	path: '/start-date',
			// 	element: <ChooseStartDate />,
			// },
			{
				path: '/checkout',
				element: <Checkout />,
			},
			{
				path: '/thank-you',
				element: <ThankYou />,
			},
			{
				path: '/manual-entry',
				element: <ManualAddressEntry />,
			},
		],
	},
	{
		element: <Private />,
		children: [],
	},
];

export default routes;

import { css } from '@emotion/react';
import { FONT_PRIMARY, FONT_SECONDARY } from '~/theme/typography';
import { darken, lighten, Theme } from '@mui/material';

const FormLabel = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiFormLabel: {
			styleOverrides: {
				root: css`
					font-family: ${FONT_SECONDARY} !important;
					font-size: 1rem !important;
				`,
			},
		},
	};
};

export default FormLabel;

import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { IBasketItem } from '~/models/BasketItem';
import { IconButton, Stack, StackProps, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Product, Session } from '~/models/sessions';
import { SessionContext } from '~/utils/contexts';
import SelectDevice from '~/components/SelectDevice';
import DialogPopup from '~/components/DialogPopup';
import api, { useGet } from '~/utils/api';
import { IDeviceCategoryResults } from '~/models/DeviceCategory';
import { useSnackbar } from '~/components/Snackbar';
import { allowBasketEdits } from '~/utils/common';
import { CheckCircle } from '@mui/icons-material';

const BasketItem: React.FC<{
	item: Session['products'][number];
	category: string;
	BasketItemIconProps?: StackProps;
	StackProps?: StackProps;
	edit?: boolean;
}> = ({ item, category, BasketItemIconProps, StackProps, edit }) => {
	const { removeItemFromSession } = useContext(SessionContext);

	const { data: categoriesResults } = useGet<IDeviceCategoryResults>(api.deviceCategories, {
		params: { all: 'true' },
	});

	const categoryFound = categoriesResults?.results?.find(
		(cat) => cat._id === item?.deviceCategoryId
	);

	useEffect(() => {
		// const filtered = categoriesResults?.results?.filter((cat) => {
		// 	console.log(cat?._id, item?.deviceCategoryId);
		// 	return cat._id == item?.deviceCategoryId;
		// });
	}, [categoriesResults?.results]);

	//63c56fab1fd83f590985663c
	//63c56fab1fd83f5909856639
	//63c56fab1fd83f5909856639

	const [openSnackbar] = useSnackbar();

	const handleDelete = async (item: Product) => {
		try {
			await removeItemFromSession(item);
			openSnackbar("You've removed an item from your basket.", 'success');
		} catch (e) {
			openSnackbar('Oops, something went wrong removing that item.', 'error');
		}
	};

	const handleEdit = (item: Product) => {
		setItemPopupVisible(true);
	};

	const [itemPopupVisible, setItemPopupVisible] = useState(false);

	console.log(categoryFound, 'categoryFound');
	return (
		<Styles>
			<DialogPopup large dialogOpen={itemPopupVisible} setDialogOpen={setItemPopupVisible}>
				<SelectDevice
					edit={edit}
					itemData={item}
					category={[
						{
							...categoryFound,
							category: categoryFound,
							name: categoryFound?.name,
							id: item.deviceCategoryId,
						},
					]}
					closePopup={() => setItemPopupVisible(false)}
				/>
			</DialogPopup>
			<Stack className="basket-item" {...StackProps}>
				<img
					src={`https://arma-karma.s3.eu-west-2.amazonaws.com/assets/Icons/${categoryFound?.code
						.toLowerCase()
						.replace(' ', '-')}.svg`}
					className="basket-item-image"
					alt="category"
				/>
				<Stack className="basket-item-text" flex={1}>
					<Typography variant="h3" className="basket-item-text-name">
						{item?.makeTitle} {item?.modelTitle}
					</Typography>
					<Typography variant="subtitle1" className="basket-item-text-price">
						Value - £{item?.price?.toLocaleString()}
					</Typography>
				</Stack>

				{allowBasketEdits() ? (
					<Stack direction="row" className="basket-item-icons" {...BasketItemIconProps}>
						<IconButton
							className="icon-wrapper delete"
							onClick={() => handleDelete(item)}
						>
							<DeleteIcon className="icon-wrapper-icon " />
						</IconButton>
						<IconButton className="icon-wrapper edit" onClick={() => handleEdit(item)}>
							<EditIcon className="icon-wrapper-icon " />
						</IconButton>
					</Stack>
				) : (
					<CheckCircle className="check" />
				)}
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	width: 100%;

	.check {
		margin-left: 60px;
		color: ${({ theme }) => theme.palette.primary.main};
	}

	.basket-item {
		flex-direction: row;
		width: 100%;
		padding: ${({ theme }) => theme.spacing(2, 2)};

		${({ theme }) => theme.breakpoints.up('md')} {
			margin-right: ${({ theme }) => theme.spacing(4)};
		}

		align-items: center;
		justify-content: space-between;

		&-image {
			max-height: 60px;
			max-width: 60px;
			padding-right: ${({ theme }) => theme.spacing(1)};
		}

		&-icon {
			max-width: 50px;
		}

		&-text {
			padding: ${({ theme }) => theme.spacing(0, 2)};
		}

		&-icons {
			gap: ${({ theme }) => theme.spacing(1)};
			margin-left: auto;

			* {
				transition: 0.25s ease;
			}

			.delete {
				background-color: ${({ theme }) => theme.palette.primary.light};

				* {
					color: ${({ theme }) => theme.palette.primary.dark};
				}

				&:hover {
					background-color: #ec6b6b;
					* {
						color: white;
					}
				}
			}

			.edit:focus {
				background: ${({ theme }) => theme.palette.primary.dark};
			}

			.edit:hover {
				background: ${({ theme }) => theme.palette.primary.main};
			}
		}

		.icon-wrapper {
			&-icon {
				transform: scale(0.9);
			}
		}
	}
`;

export default BasketItem;

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Autocomplete, Button, Checkbox, Stack, TextField, Typography } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { AddressAutofill } from '@mapbox/search-js-react';
import Postcoder from 'react-address-lookup';
import { useSession } from '~/utils/contexts';
import { Link } from 'react-router-dom';
import Fetchify from '~/components/Fetchify';
import { bakeLocalStorage, readLocalStorage } from '~/utils/storage';

const YourAddress: React.FC<{}> = () => {
	const [termsAndPrivacy, setTermsAndPrivacy] = useState<boolean>(false);
	const [offersAndNews, setOffersAndNews] = useState<boolean>(false);
	const { allowProgress, setAllowProgress, handleSubmitRef, updateSession } = useSession();

	const defaultAddresses = ['1 innovation center', 'Lukes House', 'The Whitehouse'];

	const [address, setAddress] = useState<any>(null);

	const visited = readLocalStorage('visited') || false;
	const logAddress = (addr: any) => {
		setAddress(addr);
		setAllowProgress(true);
	};

	const handleClick = () => {
		bakeLocalStorage('visited', false);
		location.reload();
	};

	useEffect(() => {
		setAllowProgress(false);

		if (address) {
			setAllowProgress(true);
		}

		if (handleSubmitRef) {
			handleSubmitRef.current = async () => {
				await updateSession({
					address: {
						postcode: address?.postal_code,
						firstLine: address?.line_1,
						secondLine: '',
						city: address?.locality,
						county: address?.province_code,
						externalApproved: true,
					},
				});

				setTimeout(() => {
					bakeLocalStorage('visited', true);
				}, 2000);
				return true;
			};
		}
	}, [address]);

	return (
		<Styles>
			<Stack className="form">
				{!visited ? (
					<>
						<Typography variant="body1" className="form-title">
							Enter your postal code for suggestions
						</Typography>
						<Fetchify
							accessToken="9d335-0393f-05960-08ddd"
							addressSelectedCallback={logAddress}
						/>
					</>
				) : (
					<Button sx={{ mt: 1, maxWidth: '230px', margin: 'auto' }} onClick={handleClick}>
						select address
					</Button>
				)}

				<Stack className="manual-entry" direction="column" alignItems="center">
					<Typography variant="h3" className="manual-entry-not-appearing">
						Not appearing?
					</Typography>
					<Typography
						onClick={() => bakeLocalStorage('visited', true)}
						variant="subtitle1"
						className="manual-entry-link"
						component={Link}
						to={'../manual-entry'}
					>
						Use manual entry
					</Typography>
				</Stack>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	display: flex;
	justify-content: center;
	.form {
		${({ theme }) => theme.breakpoints.up('md')} {
			min-width: 100%;
		}

		&-title {
			color: ${({ theme }) => theme.palette.primary.dark};
		}
	}
	.address-lookup-label {
		display: none;
	}

	.address-lookup-wrapper {
		width: 100%;
		overflow: visible;
		display: flex;
		flex-direction: column;
	}

	.address-lookup-search-button {
		width: 100%;
		background-color: ${({ theme }) => theme.palette.primary.dark};
		border-radius: 100px;
		font-weight: bold;
		font-size: 20px;
		color: white;
		padding: ${({ theme }) => theme.spacing(1)};
		order: 3;

		&.hidden {
			display: none;
		}
	}

	.address-lookup-input,
	.address-lookup-select {
		margin: ${({ theme }) => theme.spacing(3, 0)};

		overflow: visible;
		width: 100%;
		padding: 14px 12px;
		border: solid ${({ theme }) => theme.palette.primary.main} 2px;
		border-radius: 6px;
		box-shadow: 3px 3px ${({ theme }) => theme.palette.primary.main};
	}

	.address-lookup-select {
		margin-top: 0;
	}

	.manual-entry {
		gap: ${({ theme }) => theme.spacing(1)};
		width: 100%;
		justify-content: center;
		padding-top: ${({ theme }) => theme.spacing(3)};

		&-not-appearing {
			opacity: 0.7;
		}

		&-link {
			text-decoration: underline;
			font-weight: 300;
			color: ${({ theme }) => theme.palette.primary.darker};
		}
	}
`;

export default YourAddress;

import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Link,
	Stack,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { SessionContext, useSession } from '~/utils/contexts';
import { DetailsForm } from '~/components/CustomForm';
import api, { usePost } from '~/utils/api';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { MyTextInput } from '~/components/CustomInputElements';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '~/components/Snackbar';

const VerifyPhone: React.FC<{}> = () => {
	const {
		session,
		startVerifyPhone,
		confirmVerifyPhone,
		currentFormRef,
		setAllowProgress,
		setStep,
		handleSubmitRef,
	} = useSession();
	const navigate = useNavigate();
	const [openSnackbar] = useSnackbar();
	const [canResend, setCanResend] = useState(false);
	const $resendTimeout = useRef<string | number | NodeJS.Timeout | null>();
	const $validateTimeout = useRef<string | number | NodeJS.Timeout | null>();

	useEffect(() => {
		if (session?.details?.phoneNumber?.verified) return navigate('/address');
		if (session?.details?.phoneNumber?.phone) void startVerifyPhone();
	}, [session?.details?.phoneNumber?.phone]);
	useEffect(() => {
		if (!session?.details?.phoneNumber?.verified) {
			setTimeout(() => {
				setCanResend(true);
			}, 5 * 60 * 1000);
		}

		return () => {
			if ($resendTimeout.current) {
				clearTimeout($resendTimeout.current);
				$resendTimeout.current = null;
			}
		};
	}, [session?.details?.phoneNumber?.verificationRequest]);

	const handleResend = () => {
		if (!canResend) {
			openSnackbar(`You need to wait before sending a new code`, `warning`);
			return;
		}
		setCanResend(false);
		void startVerifyPhone();
	};

	return (
		<Styles>
			<Formik
				innerRef={currentFormRef}
				initialValues={{
					code: '',
				}}
				validationSchema={Yup.object({
					code: Yup.string(),
				})}
				onSubmit={async (values) => {}}
			>
				{(formik) => {
					const { values, isValid } = formik;

					handleSubmitRef!.current = async () => {
						if (!isValid) return false;
						// TODO: Make it not optional later
						if (!values.code) return true;

						try {
							const sess = await confirmVerifyPhone({ code: values.code ?? '' });
							openSnackbar('Thank you for confirming your phone number.', 'success');
						} catch (e) {
							openSnackbar('Sorry, we were unable to validate you number.', 'error');
							return false;
						}

						return true;
					};

					useEffect(() => setAllowProgress(isValid), [isValid]);

					const theme = useTheme();

					return (
						<Form className="form">
							<Stack
								justifyContent="center"
								alignItems="center"
								textAlign="center"
								spacing={3}
							>
								<Typography gutterBottom className="verify-text">
									You should have received a confirmation code on
									<br />
									<strong>
										+{session?.details?.phoneNumber?.countryCode}
										{session?.details?.phoneNumber?.phone}
									</strong>
								</Typography>

								<Box sx={{ width: '400px', maxWidth: '100%' }}>
									<MyTextInput
										label="Code"
										name="code"
										type="text"
										placeholder=""
									/>
								</Box>

								<Typography variant="body1" className="didnt-receive">
									Didn't receive a code?
								</Typography>
								<Button
									variant="text"
									onClick={() => handleResend()}
									className="send-again"
								>
									Send verification code again
								</Button>

								{/*<Typography gutterBottom>*/}
								{/*	You can skip this step if you want*/}
								{/*	<br /> to verify your phone number later*/}
								{/*</Typography>*/}

								<Divider
									sx={{ width: '400px', maxWidth: '100%' }}
									className="divider"
									textAlign="center"
								>
									or
								</Divider>

								<Button
									variant="contained"
									sx={{
										width: '400px',
										maxWidth: '100%',
										background: theme.palette.primary.dark,
									}}
									onClick={() => navigate('/address')}
									disabled={values.code !== ''}
								>
									Skip this step
								</Button>
							</Stack>
						</Form>
					);
				}}
			</Formik>
		</Styles>
	);
};

const Styles = styled.div`
	.form {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		.divider {
			//height: 3px;
			border-width: 2px;
			position: relative;
			color: grey;
		}

		.verify-text {
		}

		.send-again {
			font-size: 16px;
			text-decoration: underline;
			color: ${({ theme }) => theme.palette.primary.dark};
			font-weight: normal;
			margin-top: 0;
		}

		&-field {
			margin: ${({ theme }) => theme.spacing(1, 0)};
			width: 100%;

			&.firstname,
			&.lastname {
				width: 49%;
			}

			${({ theme }) => theme.breakpoints.up('sm')} {
				width: 49%;
			}
		}
	}

	.checkbox {
		flex-direction: row;
		align-items: center;
		padding-top: ${({ theme }) => theme.spacing(1)};

		.terms {
			&-text {
				a {
					color: ${({ theme }) => theme.palette.common.black};
					opacity: 0.6;
				}
			}
		}
	}
`;

export default VerifyPhone;

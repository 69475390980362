import { css } from '@emotion/react';
import { Theme } from '@mui/material';

const globalStyles = (theme: Theme) => css`
	@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap');
	@import url('https://use.typekit.net/btj3fdv.css');
	@import url('https://use.typekit.net/coz0sin.css');

	@font-face {
		font-family: 'Gilroy';
		src: url('/fonts/Gilroy-Light.woff2') format('woff2'),
			url('/fonts/Gilroy-Light.woff') format('woff');
		font-weight: 300;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Gilroy';
		src: url('/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
			url('/fonts/Gilroy-Extrabold.woff') format('woff');
		font-weight: bold;
		font-style: normal;
		font-display: swap;
	}

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	* {
		-webkit-overflow-scrolling: touch;

		&::-webkit-scrollbar {
			width: 0.6em;
			background-color: transparent;
		}

		&::-webkit-scrollbar-track {
			//box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			box-shadow: none;
			background-color: transparent;
			width: 0.9em;
		}

		&::-webkit-scrollbar-corner {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${theme.palette.primary.dark};
			opacity: 0.75;
			outline: none;
			border-radius: 10px;
		}
	}

	html,
	body {
	}

	body {
		overflow: unset;
	}

	html,
	body,
	#root {
		height: 100%;
	}

	div[role='group'][tabindex] {
		height: 100%;
	}

	button {
		outline: none;
		border: none;
		font-family: inherit;
	}

	a {
		text-decoration: none;
	}

	body {
		margin: 0;
		-webkit-text-size-adjust: 100%;
		font-family: ${theme.typography.body1.fontFamily};
	}

	.force-z-index {
		z-index: 1 !important;
		position: relative;
	}

	//.wrap-children {
	//	display: flex;
	//	flex-wrap: wrap;
	//}
	//
	//.next-is-wrapped,
	//.next-is-wrapped ~ * {
	//	// [flex-grow vs flex](https://github.com/angular/material/issues/2632)
	//	flex: auto;
	//}
`;

export default globalStyles;

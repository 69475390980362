import React, { Suspense, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useLocation, useSearchParams } from 'react-router-dom';
import { CircularProgress, CssBaseline, Stack, ThemeProvider, useTheme } from '@mui/material';
import SnackbarProvider, { useSnackbar } from '~/components/Snackbar';
import { Workbox } from 'workbox-window';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary as _ErrorBoundary } from '~/components/ErrorBoundary';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Global } from '@emotion/react';
import Theme from '~/components/Theme';
import { rootRoutes } from '~/routes';
import { useRoutes } from '~/utils/contexts';

import {
	faEye as fasEye,
	faArrowLeft as fasArrowLeft,
	faCloudUpload as fasCloudUpload,
	faSignatureLock,
	faLockA,
	faLock,
	faStar,
	faStarOfDavid,
} from '@fortawesome/pro-solid-svg-icons';
import {
	faPieChart as fadPieChart,
	faMountainSun as fadMountainSun,
	faServer as fadServer,
	faTablet as fadTablet,
	faSignsPost as fadSignsPost,
	faQrcode as fadQrcode,
	faImages as fadImages,
	faBarsStaggered as fadBarsStaggered,
} from '@fortawesome/pro-duotone-svg-icons';

import {
	faTiktok as fabTiktok,
	faInstagram as fabInstagram,
	faFacebookF as fabFacebookF,
	faTwitter as fabTwitter,
	faLinkedinIn as fabLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

import globalStyles from './globalStyles';
import RoutesProvider from '~/components/Routes';
import KarmadilloSpinner from '~/components/KarmadilloSpinner';
import ScrollToTop from '~/components/ScrollToTop';
import * as Sentry from '@sentry/react';
import { bakeCookie } from './utils/storage';
import { usePost } from './utils/api';
import api from './utils/api/endpoints';

library.add(
	faStar,
	faStarOfDavid,
	fasEye,
	fasArrowLeft,
	fadPieChart,
	fadMountainSun,
	fadServer,
	fadTablet,
	fadSignsPost,
	fadQrcode,
	fadImages,
	fasCloudUpload,
	fadBarsStaggered,
	fabTiktok,
	fabInstagram,
	fabFacebookF,
	fabTwitter,
	fabLinkedinIn,
	faLock
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: (error: any) =>
				!(error?.response?.status >= 400 && error?.response?.status < 500),
			refetchOnWindowFocus: false,
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
		mutations: {
			// Offline support
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
	},
});

const App = () => {
	const [initialising, setInitialising] = useState(true);
	const theme = useTheme();
	const { router, routerRoutes } = useRoutes();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const awc = searchParams.get('awc');

	const utm = searchParams.get('utm_source');

	if (awc) {
		bakeCookie('_awin_awc', awc);
	}
	if (utm) {
		bakeCookie('utm_source', utm);
	}

	return (
		<Suspense
			fallback={
				<Stack
					justifyContent="center"
					alignItems="center"
					sx={{ width: '100vw', height: '100vh' }}
				>
					<KarmadilloSpinner size={80} color="green" />
				</Stack>
			}
		>
			<Global styles={globalStyles(theme)} />
			{router}
		</Suspense>
	);
};

const Root = () => {
	return (
		<BrowserRouter>
			<Theme>
				<Sentry.ErrorBoundary
					fallback={_ErrorBoundary}
					onReset={() => {
						// reset the state of your app so the error doesn't happen again
					}}
				>
					<QueryClientProvider client={queryClient}>
						<SnackbarProvider>
							<RoutesProvider routes={rootRoutes}>
								<CssBaseline />
								<ScrollToTop />
								<App />
							</RoutesProvider>
						</SnackbarProvider>
					</QueryClientProvider>
				</Sentry.ErrorBoundary>
			</Theme>
		</BrowserRouter>
	);
};

Sentry.init({
	dsn: 'https://f4ecf241b6194ddaafd73930968cdd81@o180152.ingest.sentry.io/4504932951326720',
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
	tracesSampleRate: 1.0,
});

// @ts-ignore
createRoot(document.getElementById('root')).render(<Root />);

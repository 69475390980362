import { css } from '@emotion/react';
import { Theme } from '@mui/material';

const Checkbox = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiCheckbox: {
			styleOverrides: {
				root: css`
					.MuiSvgIcon-root {
						width: 25px;
						height: 25px;
					}
				`,
			},
		},
	};
};

export default Checkbox;

import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
	Alert,
	Button,
	IconButton,
	Rating,
	Stack,
	Typography,
	Link,
	CircularProgress,
	Box,
	Card,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSession } from '~/utils/contexts';
import GooglePayButton from '~/svg/enGB_add_to_google_wallet_add-wallet-badge.svg';
import ApplePayButton from '~/svg/US-UK_Add_to_Apple_Wallet_RGB_101421.svg';
import api from '~/utils/api';
import { useTimer } from 'react-timer-hook';
import { addSeconds } from 'date-fns';
import KarmadilloSpinner from '~/components/KarmadilloSpinner';
import { useSnackbar } from '~/components/Snackbar';

const DELAY_SECONDS = 3;
const DELAY_MINIMUM = 20;
const ThankYou: React.FC<{}> = () => {
	const { session, syncSession, updateSession } = useSession();
	const [openSnackbar] = useSnackbar();
	const { seconds, start, restart } = useTimer({
		expiryTimestamp: addSeconds(new Date(), 0),
		onExpire: async () => {
			try {
				const session = await syncSession();
				if (session?.genesys?.status !== 'active') {
					restart(addSeconds(new Date(), DELAY_SECONDS));
				}
			} catch (e) {
				restart(addSeconds(new Date(), DELAY_SECONDS));
			}
		},
		autoStart: false,
	});
	const [rating, setRating] = useState<number | null>(session?.reviewStars ?? null);

	useEffect(() => {
		start();
		if (session) {
			setRating(session.reviewStars);
		}
	}, []);

	const $trustPilot = useRef<HTMLAnchorElement>(null);

	const handleRatingChange = async (value: number | null) => {
		setRating(value);
		if (typeof value == 'number') {
			if (value < 4) {
			} else if ($trustPilot.current) {
				$trustPilot.current.click();
			}
		}
	};

	useEffect(() => {
		if (rating == null) return;

		(async () => {
			await updateSession({ reviewStars: +rating });
			openSnackbar('Thank you for your feedback.', 'success');
		})();
	}, [rating]);

	return (
		<Styles>
			<Stack className="thank">
				<Typography variant="h3" className="thank-policy">
					Your Policy Number:
				</Typography>
				<Typography variant="h3" className="thank-number">
					{session?.genesys?.policyNumber}
				</Typography>
				{/*<Typography variant="h5" className="thank-policy">*/}
				{/*	Your User Number:*/}
				{/*</Typography>*/}
				{/*<Typography variant="h5" className="thank-number">*/}
				{/*	{session?.userId}*/}
				{/*</Typography>*/}

				<a
					ref={$trustPilot}
					href="https://uk.trustpilot.com/evaluate/armakarma.insure"
					style={{ display: 'none' }}
					target="_blank"
					rel="noopener noreferrer"
				/>

				<Stack className="review-stars">
					<Typography variant="h3" className="">
						Leave us a review!
					</Typography>
					<Card className="review-stars-card">
						<Rating
							value={session?.reviewStars ?? rating}
							onChange={(event, value) => handleRatingChange(value)}
							icon={<FontAwesomeIcon icon={['fas', 'star']} />}
							emptyIcon={
								<FontAwesomeIcon
									icon={['fas', 'star']}
									style={{ color: '#EFEFEF' }}
								/>
							}
							size="large"
							className="review-stars-card-rating"
							classes={{ icon: 'review-stars-card-rating-icon' }}
						/>
					</Card>
				</Stack>

				{session?.genesys?.status === 'active' && (
					<>
						{/*<Button*/}
						{/*	className="thank-download"*/}
						{/*	variant="outlined"*/}
						{/*	component="a"*/}
						{/*	download="invoice.pdf"*/}
						{/*	href={session?.price?.lastInvoicePdfUrl}*/}
						{/*	target="_blank"*/}
						{/*>*/}
						{/*	download your latest invoice <FileDownloadIcon className="icon" />*/}
						{/*</Button>*/}
						<Typography variant="body1" className="thank-text">
							Your policy documents are on their way via email. If these don't reach
							you within the hour please get in touch.
						</Typography>
						{/*<Button*/}
						{/*	component={Link}*/}
						{/*	className="thank-download"*/}
						{/*	href={*/}
						{/*		api.baseURL +*/}
						{/*		api.sessions.documentDownload(*/}
						{/*			session?._id,*/}
						{/*			session?.genesys?.coverWordingDocumentId?.toString()*/}
						{/*		)*/}
						{/*	}*/}
						{/*	target="_blank"*/}
						{/*	variant="outlined"*/}
						{/*>*/}
						{/*	Cover Wording Document*/}
						{/*</Button>*/}
						{/*<Button*/}
						{/*	component={Link}*/}
						{/*	className="thank-download"*/}
						{/*	href={*/}
						{/*		api.baseURL +*/}
						{/*		api.sessions.documentDownload(*/}
						{/*			session?._id,*/}
						{/*			session?.genesys?.scheduleDocumentId?.toString()*/}
						{/*		)*/}
						{/*	}*/}
						{/*	target="_blank"*/}
						{/*	variant="outlined"*/}
						{/*>*/}
						{/*	Schedule Document*/}
						{/*</Button>*/}
						{/*<Button className="thank-download rating" variant="outlined">*/}
						{/*	<Rating size={'large'}></Rating>*/}
						{/*</Button>*/}
					</>
				)}

				<Stack direction="row" spacing={2} alignItems="center" pt={2}>
					{/*{session?.wallet?.google?.token &&  (*/}
					{/*@ts-ignore*/}
					<Link
						href={`https://pay.google.com/gp/v/save/${session?.wallet?.google?.token}`}
						sx={{
							cursor: 'pointer',
						}}
						target="_blank"
						className="wallet-button"
					>
						<Box className="wallet">
							<GooglePayButton />
						</Box>
					</Link>
					{/*)}*/}
					{/*{session?.wallet?.apple?.awsKey && (*/}
					{/*@ts-ignore*/}
					<Link
						className="wallet-button"
						href={`https://arma-karma.s3.eu-west-2.amazonaws.com/${session?.wallet?.apple?.awsKey}`}
						sx={{
							cursor: 'pointer',
						}}
						target="_blank"
						download
					>
						<Box className="wallet">
							<ApplePayButton />
						</Box>
					</Link>
					{/*)}*/}
				</Stack>

				{session?.genesys?.status !== 'active' && (
					<>
						<Typography pt={2} gutterBottom>
							Waiting for payment confirmation...
						</Typography>
						<KarmadilloSpinner size={40} color="green" />
					</>
				)}

				<Typography variant="h2" className="stay-in-touch">
					Stay in touch!
				</Typography>
				<Typography variant="h3" className="follow-socials">
					Follow our socials & keep up to date with us!
				</Typography>

				<Stack className="social-links">
					<IconButton
						className="social-links-button"
						component="a"
						href="https://www.tiktok.com/@armakarmauk/"
						target={'_blank'}
						referrerPolicy="no-referrer"
					>
						<FontAwesomeIcon icon={['fab', 'tiktok']} />
					</IconButton>
					<IconButton
						className="social-links-button"
						component="a"
						href="https://www.instagram.com/armakarmauk/"
						target={'_blank'}
						referrerPolicy="no-referrer"
					>
						<FontAwesomeIcon icon={['fab', 'instagram']} />
					</IconButton>
					<IconButton
						className="social-links-button"
						component="a"
						href="https://www.facebook.com/ArmaKarmaUK/"
						target={'_blank'}
						referrerPolicy="no-referrer"
					>
						<FontAwesomeIcon icon={['fab', 'facebook-f']} />
					</IconButton>
					<IconButton
						className="social-links-button"
						component="a"
						href="https://twitter.com/ArmaKarmaUK"
						target={'_blank'}
						referrerPolicy="no-referrer"
					>
						<FontAwesomeIcon icon={['fab', 'twitter']} />
					</IconButton>
					<IconButton
						className="social-links-button"
						component="a"
						href="https://www.linkedin.com/company/armakarma/"
						target={'_blank'}
						referrerPolicy="no-referrer"
					>
						<FontAwesomeIcon icon={['fab', 'linkedin-in']} />
					</IconButton>
				</Stack>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	.wallet {
		height: 55px;
		* {
			height: 100%;
			width: 200px;
		}

		&-button svg {
			max-width: 90%;
			overflow: visible;
		}

		${({ theme }) => theme.breakpoints.only('xs')} {
			&-button {
				max-width: 40vw;
				svg {
					max-width: 100%;
				}
			}
		}
	}

	.review-stars {
		align-items: center;
		gap: ${({ theme }) => theme.spacing(1)};
		margin: ${({ theme }) => theme.spacing(3, 0)};

		&-card {
			&-rating {
				&-icon {
					width: 30px;
					margin: ${({ theme }) => theme.spacing(0, 0.5)};
				}
			}
			padding: ${({ theme }) => theme.spacing(1, 3)};
			border: 2px solid ${({ theme }) => theme.palette.primary.main};
			box-shadow: 2px 2px 0px ${({ theme }) => theme.palette.primary.main};
		}
	}

	.thank {
		justify-content: center;
		align-items: center;

		&-text {
			padding: ${({ theme }) => theme.spacing(3)};
			max-width: 600px;
			text-align: center;
		}

		&-number {
			background: ${({ theme }) => theme.palette.primary.dark};
			color: white;
			border-radius: 50px;
			padding: ${({ theme }) => theme.spacing(1, 3)};
			margin: ${({ theme }) => theme.spacing(1, 2)};
		}

		&-download {
			margin-top: ${({ theme }) => theme.spacing(4)};
			border: solid 3px ${({ theme }) => theme.palette.primary.main};
			box-shadow: 3px 3px 0px 0px ${({ theme }) => theme.palette.primary.main};
			border-radius: 8px;
			padding: ${({ theme }) => theme.spacing(2, 3)};
			color: grey;

			width: 100%;
			max-width: 500px;
			min-height: 80px;

			&.rating {
				background-color: white;
			}

			.icon {
				color: ${({ theme }) => theme.palette.primary.main};
				margin-left: ${({ theme }) => theme.spacing(2)};
				font-size: 28px;
			}
		}
	}

	.stay-in-touch {
		margin-top: ${({ theme }) => theme.spacing(4)};
	}

	.social-links {
		flex-direction: row;
		justify-content: space-between;
		margin-top: ${({ theme }) => theme.spacing(1)};

		&-button {
			margin: ${({ theme }) => theme.spacing(1)};
			border-radius: 30px;
			width: 30px;
			height: 30px;
			background-color: ${({ theme }) => theme.palette.primary.main};
			color: ${({ theme }) => theme.palette.background.paper};
		}
	}
`;

export default ThankYou;

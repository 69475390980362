import { css } from '@emotion/react';
import { Theme } from '@mui/material';

const Accordion = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiAccordion: {
			styleOverrides: {
				root: css`
					&:before {
						opacity: 0;
					}
				`,
			},
		},
	};
};

export default Accordion;

import React, { useEffect, useRef, useState } from 'react';
import { Stack, TextField } from '@mui/material';
import manualAddressEntry from '~/components/ManualAddressEntry';
import { useLocation } from 'react-router-dom';
import usePrevious from '~/hooks/usePrevious';
import { useSession } from '~/utils/contexts';

/**
 * Fetchify component intended to mimic the Postcoder component
 */
const Fetchify: React.FC<{
	accessToken: string;
	showManualEntry?: boolean;
	overrideManualEntryKeys?: Record<string, string>;
	addressSelectedCallback: (address: any) => void;
}> = ({ accessToken, overrideManualEntryKeys, showManualEntry, addressSelectedCallback }) => {
	const { session } = useSession();

	const [value, setValue] = useState<string>();
	const instance = useRef<any>();

	useEffect(() => {
		try {
			instance.current = new (window as any).clickToAddress({
				accessToken,
				domMode: 'id',
				enabledCountries: ['gbr'],
				countryMatchWith: 'iso_3',
				onResultSelected: (_c2a: any, _elements: any, address: any) => {
					setValue(
						`${address.line_1}, ${address.line_2}, ${address.locality}, ${address.province_code}, ${address.postal_code}, ${address.country_name}`
					);
					addressSelectedCallback?.(address);
				},
			});
		} catch (e) {
			// already initialised
		}

		instance.current?.attach?.({
			search: 'search',
			...(overrideManualEntryKeys ??
				(showManualEntry
					? {
							line_1: 'line_1',
							line_2: 'line_2',
							town: 'town',
							postcode: 'postcode',
							county: 'county',
							country: 'country',
					  }
					: {})),
		});
	}, []);

	return (
		<Stack direction="column" spacing={2}>
			<TextField
				label="Search"
				variant="filled"
				id="search"
				value={value}
				fullWidth
				onChange={(e) => {
					setValue(e.target.value);
				}}
			/>
			{showManualEntry && (
				<>
					<TextField label="First Line" variant="filled" id="line_1" />
					<TextField label="Second Line" variant="filled" id="line_2" />
					<TextField label="Town" variant="filled" id="town" />
					<TextField label="Postcode" variant="filled" id="postcode" />
					<TextField label="County" variant="filled" id="county" />
					<TextField label="Country" variant="filled" id="country" />
				</>
			)}
		</Stack>
	);
};

export default Fetchify;

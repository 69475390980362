import * as React from 'react';
import { Global } from '@emotion/react';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import container from '~/theme/components/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';
import { useCallback, useContext, useEffect, useRef } from 'react';
import theme from '~/components/Theme';
import DrawerContent from '~/components/DrawerContent';
import { IconButton, Stack } from '@mui/material';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import api, { useGet } from '~/utils/api';
import { SessionContext } from '~/utils/contexts';
import usePrevious from '~/hooks/usePrevious';
import { diff } from 'deep-diff';
import { Skeleton } from '@mui/material';

const drawerBleeding = 100;

const Puller = styled(Box)(({ theme }) => ({
	width: 30,
	height: 6,
	backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
	borderRadius: 3,
	position: 'absolute',
	top: 8,
	left: 'calc(50% - 15px)',
}));

export default function SwipeableEdgeDrawer(props: any) {
	const $drawer = useRef<HTMLDivElement>(null);

	let open = props.open;
	let setOpen = props.setOpen;

	useEffect(() => {
		const handleBodyClick = (e: Event) => {
			const $element = e?.target as HTMLElement;
			const $privateSwipeArea = document.querySelector('.PrivateSwipeArea-root');

			if (!!$privateSwipeArea && $element?.className?.includes('PrivateSwipeArea-root')) {
				const width = $element?.getBoundingClientRect?.()?.width / 2;
				const isRightSide = e.clientX >= width;

				if (isRightSide) {
					setOpen(true);
				}
			}
		};

		document.body.addEventListener('click', handleBodyClick);

		return () => document.removeEventListener('click', handleBodyClick);
	}, [$drawer]);

	//Needed to enable drawer area beneath private swipe area to be clickable when drawer open
	//how you like those ifs jacob??
	useEffect(() => {
		const $privateSwipeArea = document.querySelector('.PrivateSwipeArea-root');
		if ($privateSwipeArea) {
			if (open) {
				$privateSwipeArea.classList.add('disable-pointer-events');
			} else {
				$privateSwipeArea.classList.remove('disable-pointer-events');
			}
		}
	}, [open]);

	const toggleDrawer = (newOpen: boolean) => () => {
		setOpen(newOpen);
	};

	const drawerContainer = useRef(null);
	const { session, totalPrice, isFetchingTotalPrice } = useContext(SessionContext);

	return (
		<Styles>
			<Box
				className="wrapper"
				sx={{ height: '100%', position: 'relative', pointerEvents: 'all' }}
				ref={drawerContainer}
			>
				<Global
					styles={{
						'.MuiDrawer-root > .MuiPaper-root': {
							height: `calc(80%)`,
							overflow: 'visible',
						},
					}}
				/>
				<SwipeableDrawer
					ref={$drawer}
					disablePortal
					container={drawerContainer.current}
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					swipeAreaWidth={drawerBleeding}
					disableSwipeToOpen={false}
					ModalProps={{
						keepMounted: true,
					}}
					sx={{
						zIndex: 998,
					}}
					anchor="bottom"
				>
					<Box
						onClick={(event) => {
							setOpen(true);
							event.stopPropagation();
						}}
						sx={{
							position: 'absolute',
							top: -drawerBleeding,
							borderTopLeftRadius: 8,
							borderTopRightRadius: 8,
							visibility: 'visible',
							right: 0,
							left: 0,
							background: '#EFEFEF',
							pointerEvents: 'all',
							cursor: 'pointer',
						}}
						className="drawer-content"
					>
						<Puller />
						<Stack className="price-stack">
							<div className="space"></div>
							<Stack className="total">
								<Box
									className="basket-wrapper"
									onClick={(event) => {
										setOpen(false);
										event.stopPropagation();
									}}
								>
									<Stack className="total">
										<Stack>
											<Typography variant="h1" className="price-stack-price">
												{isFetchingTotalPrice ? (
													<Stack
														fontFamily="gilroy"
														direction="row"
														spacing={0.5}
													>
														£<Skeleton variant="text" width={30} />.
														<Skeleton variant="text" width={60} />
													</Stack>
												) : session?.products?.length === 0 ? (
													<Box fontFamily="gilroy">£0.00</Box>
												) : (
													<Stack>
														<Box fontFamily="gilroy">
															£
															{totalPrice?.quote?.toFixed(2) ??
																'0.00'}
															<Typography
																fontFamily="gilroy"
																component="span"
																variant="h6"
																textTransform="lowercase"
																sx={{ mr: 1 }}
															>
																/month
															</Typography>
														</Box>
													</Stack>
												)}
											</Typography>
											<Typography pl={1} pt={0.5} className="ipt">
												inclusive of IPT
											</Typography>
										</Stack>

										{!['thank-you', 'checkout'].includes(
											location?.pathname?.split('/').pop() ?? ''
										) && (
											<Box className="basket-wrapper">
												<ShoppingBasketIcon className="basket-icon" />
												<Typography
													variant="body2"
													component="span"
													className="basket-wrapper-number"
												>
													{session?.products?.length ?? 0}
												</Typography>
											</Box>
										)}
									</Stack>
								</Box>
							</Stack>

							<IconButton
								sx={{ zIndex: 1000 }}
								className="icon-wrapper"
								onClick={(event) => {
									setOpen(false);
									event.stopPropagation();
								}}
							>
								<ExpandMoreIcon
									sx={{ transform: `rotateZ(${open ? 0 : 180}deg)` }}
									className="icon-wrapper-icon"
									id="drawerErrorButton"
								/>
							</IconButton>
						</Stack>
					</Box>
					<DrawerContent setOpen={setOpen} />
				</SwipeableDrawer>
			</Box>
		</Styles>
	);
}

const Styles = styled.div`
	background-color: ${({ theme }) => theme.palette.primary.light};
	.ipt {
		height: 0;
		color: #aeacac;
		font-weight: bold;
	}
	.disable-pointer-events {
		pointer-events: none;
	}

	.drawer-content {
		height: calc(100% + ${drawerBleeding}px);
	}

	.price-stack {
		flex-direction: row;
		width: 100%;
		justify-content: space-around;
		padding-top: ${({ theme }) => theme.spacing(4)};
		align-items: center;
	}

	.total {
		flex-direction: row;
		align-items: center;

		.price-stack-price {
			padding: 0 0.5rem;
		}
	}

	.basket-icon {
		font-size: 40px;
		color: ${({ theme }) => theme.palette.primary.dark};
	}

	.icon-wrapper {
		background-color: ${({ theme }) => theme.palette.primary.dark};

		&:active,
		&:focus {
			background-color: ${({ theme }) => theme.palette.primary.dark};
		}

		&-icon {
			color: white;
		}
	}

	.basket-wrapper {
		position: relative;

		&-number {
			width: 24px;
			font-weight: bold;
			aspect-ratio: 1;
			color: white;
			border-radius: 50%;
			font-size: 16px;
			text-align: center;
			background-color: ${({ theme }) => theme.palette.primary.main};
			position: absolute;
			bottom: -5%;
			right: -18%;
		}
	}
`;

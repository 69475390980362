import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import {
	CardElement,
	Elements,
	useStripe,
	useElements,
	PaymentElement,
} from '@stripe/react-stripe-js';
import { useSession } from '~/utils/contexts';
import useDidUpdateEffect from '~/hooks/useDidUpdateEffect';
import { Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KarmadilloSpinner from '~/components/KarmadilloSpinner';
import { useSnackbar } from '~/components/Snackbar';
import { Session } from '~/models/sessions';
import usePrevious from '~/hooks/usePrevious';

const stripePromise = loadStripe(process.env.STRIPE_KEY);
const CheckoutForm: React.FC = () => {
	const elements = useElements();
	const stripe = useStripe();
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// TODO: Sean handle URL params that show a failure etc.
				return_url: `${window.location.origin}/thank-you`,
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		// if (error.type === 'card_error' || error.type === 'validation_error') {
		// 	setMessage(error.message);
		// } else {
		// 	setMessage('An unexpected error occurred.');
		// }

		setIsLoading(false);
	};

	if (!stripe || !elements) return <div>Loading</div>;

	const theme = useTheme();

	return (
		<form onSubmit={handleSubmit}>
			<Stack
				justifyContent="flex-end"
				alignItems="center"
				direction="row"
				className="stripe"
				sx={{ mb: 1 }}
			>
				<Typography variant="body1" className="">
					Powered by
				</Typography>
				<img src="/stripe.png" alt="stripe logo" style={{ maxWidth: '60px' }} />
				<FontAwesomeIcon
					icon={['fas', 'lock']}
					color={theme.palette.primary.dark}
					style={{ marginBottom: 1 }}
				/>
			</Stack>
			<PaymentElement />
			<Stack justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
				<Button
					type="submit"
					className="submit"
					startIcon={isLoading ? <KarmadilloSpinner size={20} color="green" /> : null}
					disabled={isLoading}
					sx={{ mt: 2, width: '400px', maxWidth: '90vw' }}
				>
					Start Cover
				</Button>
			</Stack>
		</form>
	);
};

const CheckoutFormWrapper: React.FC<{}> = () => {
	const { session, finalizeSession } = useSession();
	const [openSnackbar] = useSnackbar();
	const [isDelayed, setIsDelayed] = useState(false);
	const location = useLocation();
	const [subscriptionSecret, setSubscriptionSecret] = useState<string>();
	const $attempts = useRef<number>(0);

	useEffect(() => {
		(async () => {
			if (session?._id && location.pathname === '/checkout') {
				let session: Session | undefined = undefined;
				while (!session?.genesys?.paymentIntent && $attempts.current < 3) {
					$attempts.current++;
					try {
						session = await finalizeSession();
						if (session?.genesys?.paymentIntent)
							setSubscriptionSecret(session.genesys.paymentIntent);
					} catch (e) {
						console.error(e);
						setIsDelayed(true);
						openSnackbar(
							'There was an error finalizing your session. Trying again...',
							'warning'
						);
					}

					await new Promise((resolve) => setTimeout(resolve, 1500));
				}

				if ($attempts.current >= 3) {
					openSnackbar(
						'There was an error finalizing your session. Please contact our support team.',
						'error'
					);
				}
			}
		})();
	}, [session?._id, session?.discountCode]);

	const previousCode = usePrevious(session?.discountCode);
	useEffect(() => {
		if (session?.genesys?.policyId && previousCode != session?.discountCode) {
			void finalizeSession(true).then((session) => {
				if (session?.genesys?.paymentIntent)
					setSubscriptionSecret(session.genesys.paymentIntent);
			});
		}
	}, [session?.discountCode]);

	if (!subscriptionSecret)
		return (
			<Stack
				sx={{
					width: '100%',
					height: '250px',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				spacing={4}
			>
				<Typography variant="h3" className="loading-text">
					One moment...
				</Typography>
				{isDelayed && (
					<Typography className="loading-text">
						Sorry, this is taking longer than usual.
					</Typography>
				)}
				<KarmadilloSpinner size={50} color="green" />
			</Stack>
		);

	const theme = useTheme();

	const stripeTheme: Appearance = {
		theme: 'stripe',
		labels: 'floating',
		variables: {
			colorPrimary: theme.palette.primary.main,
			colorBackground: '#ffffff',
			colorText: theme.palette.primary.darker,
			colorDanger: '#df1b41',
			fontFamily: 'Gilroy, Open sans, Helvetica, Arial, sans-serif',
			spacingUnit: '4px',
			borderRadius: '10px',
			// See all possible variables below
		},
		rules: {
			'.Label': {
				fontWeight: 'bold',
				color: theme.palette.primary.darker,
				opacity: '0.75',
			},

			'.Input': {
				border: `3px solid ${theme.palette.primary.main}`,
				boxShadow: `3px 3px 0px 0px ${theme.palette.primary.main}`,
				marginBottom: `${theme.spacing(1)}`,
			},
		},
	};

	return (
		<Elements
			key={subscriptionSecret}
			stripe={stripePromise}
			options={{
				clientSecret: subscriptionSecret,
				loader: 'always',
				appearance: stripeTheme,
			}}
		>
			<CheckoutForm />
		</Elements>
	);
};

export default CheckoutFormWrapper;

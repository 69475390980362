import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import DialogPopup from '~/components/DialogPopup';
import { Button, Grid, Stack, Typography } from '@mui/material';
import GridItem from '~/components/GridItem';
import { ICharity } from '~/models/Charity';
import { SessionContext } from '~/utils/contexts';
import api, { useGet } from '~/utils/api';

export interface IAddOn {
	title: string;
	description: string;
	link: string;
	icon: string;
}

const AddOns: React.FC<{}> = () => {
	const [addOns, setAddons] = useState<IAddOn[]>([
		{
			title: 'Household gadgets',
			description: 'here is more info about the Household Gadgets',
			link: 'https://google.com',
			icon: 'https://arma-karma.s3.eu-west-2.amazonaws.com/household+gadgets.svg',
		},
		{
			title: 'Key Covers',
			description: 'here is more info about the Key covers add on',
			link: 'https://google.com',
			icon: 'https://arma-karma.s3.eu-west-2.amazonaws.com/key+covers.svg',
		},
		{
			title: 'Personal cyber',
			description: 'Protection for all your mobile accounts',
			link: 'https://google.com',
			icon: 'https://arma-karma.s3.eu-west-2.amazonaws.com/personal+cyber.svg',
		},
		{
			title: 'Legal cover',
			description: 'Worry no more about expensive legal fees and settlements',
			link: 'https://google.com',
			icon: 'https://arma-karma.s3.eu-west-2.amazonaws.com/legal+cover.svg',
		},
	]);

	const [popupVisible, setPopVisible] = useState<boolean>(false);
	const [currentAddOn, setCurrentAddOn] = useState<IAddOn>(addOns[0]);

	const { session, addAddOnToSession, removeAddOnFromSession, setAllowProgress } =
		useContext(SessionContext);

	const [selectedAddOns, setSelectedAddOns] = useState<string[]>(session?.addOns ?? []);

	useEffect(() => {
		if (session?.addOns && !selectedAddOns.length) setSelectedAddOns(session?.addOns);
	}, [session?.addOns?.length]);

	const { data: totalPrice } = useGet<{ quote: number }>(api.sessions.quote(session?._id), {
		enabled: !!session?._id,
	});

	const addToSession = async (item: string) => {
		let oldSelected = selectedAddOns;
		setSelectedAddOns((selectedAddOns) => {
			return [...selectedAddOns, item];
		});
		try {
			const session = await addAddOnToSession([item]);
			setSelectedAddOns(session?.addOns ?? selectedAddOns);
		} catch (e) {
			setSelectedAddOns(selectedAddOns);
		}
	};

	const removeFromSession = async (item: string) => {
		setSelectedAddOns((selectedAddOns) => {
			return selectedAddOns.filter((title) => title !== item);
		});
		try {
			const session = await removeAddOnFromSession(item);
			setSelectedAddOns(session?.addOns ?? selectedAddOns);
		} catch (e) {
			setSelectedAddOns(selectedAddOns);
		}
	};

	setAllowProgress(true);

	const handleAddOnClicked = (item: IAddOn) => {
		if (!selectedAddOns.includes(item.title)) {
			addToSession(item.title);
		}
		setPopVisible(false);
	};

	return (
		<Styles>
			<DialogPopup dialogOpen={popupVisible} setDialogOpen={setPopVisible} wrapper={Styles}>
				<Stack className="charity-info-stack" sx={{ maxWidth: '350px' }}>
					<Typography variant="h3" className="dialog-title">
						More info about {currentAddOn?.title}
					</Typography>

					<Typography variant="body1" className="dialog-body" sx={{ py: 2 }}>
						{currentAddOn?.description}
					</Typography>

					<Typography
						variant="h3"
						component={'a'}
						href={currentAddOn?.link}
						target="_blank"
						className="dialog-link"
						sx={{ pb: 2, color: 'black' }}
					>
						More info
					</Typography>
					<Button
						variant="contained"
						onClick={() => {
							handleAddOnClicked(currentAddOn);
						}}
					>
						Add me
					</Button>
					<Typography pt={2}>
						Your new price will be{' '}
						<span className="price">£{((totalPrice?.quote ?? 0) + 2).toFixed(2)}</span>
						/mo
					</Typography>
				</Stack>
			</DialogPopup>

			<Stack justifyContent="center" alignItems="center" className="add-on-stack">
				<Grid container spacing={3} sx={{ width: '100%' }} justifyContent="center">
					{addOns?.map((item, index) => (
						<Grid
							item
							xs={5}
							md={6}
							lg={3}
							key={index}
							onClick={() => {
								if (selectedAddOns.includes(item.title)) {
									removeFromSession(item.title);
								} else {
									setCurrentAddOn(item);
									setPopVisible(true);
								}
							}}
						>
							<GridItem
								status={selectedAddOns.includes(item.title) ? 'selected' : 'white'}
								name={item?.title}
								img={item?.icon.toLowerCase()}
								showQuestion={true}
								_id={index.toString()}
							/>
						</Grid>
					))}
				</Grid>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	.charity-stack {
		gap: ${({ theme }) => theme.spacing(4)};
	}

	.price {
		color: ${({ theme }) => theme.palette.primary.main};
		font-weight: 700;
	}
`;

export default AddOns;
